/* import __COLOCATED_TEMPLATE__ from './descriptor-details-handler.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import { dropTask } from 'ember-concurrency-decorators';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { CONVERSATION_OBJECT_TYPE_NAME } from 'embercom/models/custom-objects/constants/object-types';
import {
  ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP,
  ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP,
} from 'embercom/models/custom-objects/constants/attribute-descriptor-types';
import { REVERSE_CARDINALITY_NAME_MAP } from 'embercom/models/custom-objects/constants/relationship-cardinalities';
import { REFERENCES_ONE, REFERENCES_MANY } from 'embercom/models/objects/constants/reference-types';
import { post } from 'embercom/lib/ajax';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { hasFeature } from 'embercom/helpers/has-feature';
import { trackedReset } from 'tracked-toolbox';
import generateUUID from 'embercom/lib/uuid-generator';
import { schedule } from '@ember/runloop';
import scrollIntoViewIfNotVisible from 'embercom/lib/scroll-into-view-if-not-visible';

const LIST_OPTIONS_LIMIT = 250;
const LIST_OPTIONS_INCREASED_LIMIT = 500;
const MIN_LIST_OPTION_DESCRIPTION_LENGTH = 10;
const MAX_LIST_OPTION_DESCRIPTION_LENGTH = 1000;

export default class extends Component {
  @service store;
  @service notificationsService;
  @service appService;
  @service intercomEventService;
  @service customObjectsService;
  @service intl;
  @service permissionsService;
  @service session;

  sourceObjectName = CONVERSATION_OBJECT_TYPE_NAME;

  @tracked editMode = isPresent(this.args.attributeToEdit);
  @tracked dataType = this.editMode
    ? this.args.attributeToEdit.dataType
    : this.typeDropdownItems.firstObject.value;
  @tracked displayableDataType = this.editMode
    ? this.args.attributeToEdit.displayableDataType
    : this.typeDropdownItems.firstObject.text;
  @tracked listOptions = this.editMode ? this.args.attributeToEdit.unArchivedListOptions : [];
  @tracked order = this.editMode ? this.args.attributeToEdit.order : 0;
  @tracked isVisibilityLimited = this.editMode
    ? this.args.attributeToEdit.isVisibilityLimited
    : false;
  @tracked requiredAttributeValue = this.editMode
    ? this.getRequiredAttributeValue(this.args.attributeToEdit)
    : 'not_required';
  @tracked relatedObjectTypeIdentifier;
  @tracked relatedObjectAttributeName;
  @tracked relatedObjectAttributeDescription;
  @tracked relationshipCardinality = this.args.attributeToEdit?.cardinality;
  @tracked showEditCardinalityWarning = false;
  @tracked showEditReferenceTypeWarning = false;
  @tracked referenceType = this.args.attributeToEdit?.reference?.referenceType || REFERENCES_MANY;
  @tracked referencedObjectTypeIdentifier =
    this.args.attributeToEdit?.reference?.referencedObjectTypeIdentifier;
  @tracked isDefaultAttribute = this.args.attributeToEdit?.isBuiltIn || false;
  @tracked isDeletable = !this.isDefaultAttribute;
  @tracked isEditable = !this.isDefaultAttribute;
  @tracked isClassificationAttribute =
    this.args.attributeToEdit?.isClassificationAttribute || false;
  @tracked isTicketDescriptor = isPresent(this.args.ticketType);

  @tracked userConversationAttachmentsEnabled;
  @tracked newRecord;
  @tracked showUnsavedChangesModal = false;
  @trackedReset('args.startWithTab') currentTab = this.args.startWithTab || 'details';

  constructor() {
    super(...arguments);

    if (!this.editMode) {
      this.newRecord = this.createNewRecord();
    }

    if (this.args.descriptorTemplateParams) {
      this.currentAttribute.name = this.args.descriptorTemplateParams.name;
      this.currentAttribute.description = this.args.descriptorTemplateParams.description;
      this.dataType = this.args.descriptorTemplateParams.dataType;
      this.listOptions = this.args.descriptorTemplateParams.listOptionLabels.map((label) =>
        this.createListOptionObject(label),
      );
    }

    if (this.isFilesType) {
      this.currentAttribute.requiredToCreateForUsers = false;
    }

    if (this.isInternalTicketType) {
      this.currentAttribute.visibleToUsers = false;
      this.currentAttribute.requiredToCreateForUsers = false;
    }

    if (this.isFilesType) {
      this.setUserConversationAttachmentsEnabled();
    }
  }

  get descriptorDefaultAttrs() {
    return {
      visibleOnCreate: !this.isConversationTicketType,
      visibleToUsers: !this.isConversationTicketType,
    };
  }

  createNewRecord() {
    let record = this.store.createRecord('conversation-attributes/descriptor');
    record.setProperties(this.descriptorDefaultAttrs);

    return record;
  }

  get currentAttribute() {
    if (this.editMode) {
      return this.args.attributeToEdit;
    } else {
      return this.newRecord;
    }
  }

  setUserConversationAttachmentsEnabled() {
    if (this.permissionsService.currentAdminCan('can_access_workspace_settings')) {
      // eslint-disable-next-line promise/prefer-await-to-then
      this.store.findRecord('attachment-settings', this.appService.app.id).then((settings) => {
        this.userConversationAttachmentsEnabled =
          settings.userConversationMediaEnabled || settings.userConversationFilesEnabled;
      });
    } else {
      //If user does not have access security settings default to true.
      this.userConversationAttachmentsEnabled = true;
    }
  }

  get typeDropdownItems() {
    if (this.args.availableTypes) {
      return this.args.availableTypes;
    }

    let options = [
      {
        text: this.intl.t('settings.conversation-attributes.descriptor.text'),
        value: 'string',
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['string'],
      },
      {
        text: this.intl.t('settings.conversation-attributes.descriptor.list'),
        value: 'list',
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['list'],
      },
      {
        text: this.intl.t('settings.conversation-attributes.descriptor.number'),
        value: 'integer',
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['integer'],
      },
      {
        text: this.intl.t('settings.conversation-attributes.descriptor.decimal'),
        value: 'decimal',
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['decimal'],
      },
      {
        text: this.intl.t('settings.conversation-attributes.descriptor.boolean'),
        value: 'boolean',
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['boolean'],
      },
      {
        text: this.intl.t('settings.conversation-attributes.descriptor.date-time'),
        value: 'datetime',
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['datetime'],
      },
    ];

    if (this.isConversationTicketType) {
      options.push({
        text: this.intl.t('settings.conversation-attributes.descriptor.reference'),
        value: 'relationship',
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['relationship'],
      });
    }

    if (
      !this.isConversationTicketType ||
      hasFeature('team-actions-fin-file-upload', this.appService)
    ) {
      options.push({
        text: this.intl.t('settings.conversation-attributes.descriptor.files'),
        value: 'files',
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP['files'],
      });
    }

    return options;
  }

  @action
  onReferenceTypeToggle() {
    let existingReferenceType = this.args.attributeToEdit?.reference?.referenceType;

    this.referenceType = this.referenceType === REFERENCES_MANY ? REFERENCES_ONE : REFERENCES_MANY;
    this.showEditReferenceTypeWarning =
      isPresent(existingReferenceType) && this.referenceType !== existingReferenceType;
  }

  get isStringType() {
    return this.dataType === 'string';
  }

  get isListType() {
    return this.dataType === 'list';
  }

  get isFilesType() {
    return this.dataType === 'files';
  }

  get canEditConditions() {
    // Ensure the conditions tab is shown when creating a second attribute.
    // This applies only if the attribute being edited is not the first one.

    // Two scenaiors where conditions are available:
    // 1. When there is more than one attribute.
    // 2. When there is only one attribute saved, and a second one is being created. In this special case, we want to allow conditions to be edited.
    let hasAvailableAttributes =
      (this.allAttributes.length === 1 &&
        this.args.attributeToEdit?.id !== this.allAttributes[0]?.id) ||
      this.allAttributes.length > 1;

    return (
      this.args.canEditConditions !== false &&
      ((this.editMode && this.currentAttribute.dataType === 'list') || this.dataType === 'list') &&
      this.haveListOptions &&
      hasAvailableAttributes
    );
  }

  get haveListOptions() {
    return (
      this.currentAttribute.listOptions.length > 0 &&
      this.currentAttribute.listOptions.any((option) => option.label.length > 0)
    );
  }

  get conditionsUnavailableErrorMessage() {
    if (this.currentAttribute.dataType !== 'list' && this.dataType !== 'list') {
      return this.intl.t(
        'settings.conversation-attributes.conditions-side-drawer.conditions-unavailable-for-non-list-attributes',
      );
    }

    // Two scenaiors where conditions cannot be added:
    // 1. When there are no other attributes to create conditions for (all attributes count is 0).
    // 2. When there is only one attribute and it is the one being edited.
    let noAvailableAttributes =
      (this.allAttributes.length === 1 &&
        this.args.attributeToEdit?.id === this.allAttributes[0]?.id) ||
      this.allAttributes.length === 0;

    if (!this.allAttributes || noAvailableAttributes) {
      return this.intl.t(
        'settings.conversation-attributes.conditions-side-drawer.conditions-unavailable-when-no-other-attributes',
      );
    }

    if (!this.haveListOptions) {
      return this.intl.t(
        'settings.conversation-attributes.conditions-side-drawer.conditions-unavailable-when-no-list-options',
      );
    }
  }

  get allAttributes() {
    if (!this.args.allAttributes) {
      return [];
    }
    return this.args.allAttributes.rejectBy('archived').rejectBy('isBuiltIn');
  }

  get isInternalTicketType() {
    if (this.appService.app.canUseTicketsModelV3) {
      switch (this.args.ticketType?.category) {
        case TicketCategory.Task:
          return this.args.ticketType?.internal;
        case TicketCategory.Tracker:
          return true;
        default:
          return false;
      }
    } else {
      return this.args.ticketType?.internal;
    }
  }

  get showAttachmentsDisabledHint() {
    return this.isFilesType && !this.userConversationAttachmentsEnabled;
  }

  get customerSharingHint() {
    if (this.showAttachmentsDisabledHint) {
      return this.intl.t(
        'settings.conversation-attributes.file-attribute-attachments-disabled-hint',
        {
          htmlSafe: true,
        },
      );
    }
    return null;
  }

  get filesRequiredTooltip() {
    return this.isFilesType
      ? this.intl.t('settings.conversation-attributes.file-attribute-required')
      : null;
  }

  get optionsLimit() {
    return this.appService.app.canUseIncreasedListOptionsLimit
      ? LIST_OPTIONS_INCREASED_LIMIT
      : LIST_OPTIONS_LIMIT;
  }

  get optionLimitReached() {
    return this.listOptions.length >= this.optionsLimit;
  }

  get attributeIsArchived() {
    return this.args.attributeToEdit?.archived;
  }

  get canArchiveAttribute() {
    return this.editMode && !this.attributeIsArchived && this.isDeletable;
  }

  createListOptionObject(label = '') {
    return this.store.createRecord('conversation-attributes/list-option', {
      id: generateUUID(),
      label,
      descriptor: this.currentAttribute,
    });
  }

  get isValid() {
    let isVisibilityValid =
      !this.isVisibilityLimited ||
      (this.currentAttribute.visibleToTeamIds &&
        this.currentAttribute.visibleToTeamIds.length !== 0);
    return (
      this.currentAttribute.name.trim() !== '' && isVisibilityValid && !this.anyConditionInvalid
    );
  }

  get anyConditionInvalid() {
    return this.currentAttribute?.dependentConditions.any((condition) => {
      return !condition.validations.isValid;
    });
  }

  get isConversationTicketType() {
    if (this.args.ticketType === undefined) {
      return true;
    }

    return this.args.ticketType.isConversationTicketType;
  }

  get isTextMultiline() {
    return this.dataType === 'string' && this.currentAttribute.multiline;
  }

  get namePlaceholder() {
    return this.isConversationTicketType
      ? this.intl.t('settings.conversation-attributes.name-placeholder-example')
      : this.intl.t('settings.conversation-attributes.name-hint');
  }

  get descriptionValue() {
    if (this.appService.app.canEditTicketDesciptionDescriptions) {
      return this.description;
    }
    return this.isDefaultAttribute ? this.args.attributeToEdit?.description : this.description;
  }

  get descriptionPlaceholder() {
    return this.isConversationTicketType
      ? this.intl.t('settings.conversation-attributes.description-placeholder-example')
      : this.intl.t('settings.conversation-attributes.description-hint');
  }

  get attributeLimitWarning() {
    let attributeType = this.isConversationTicketType ? 'conversation' : 'ticket';
    return this.intl.t('settings.conversation-attributes.attribute-limit-warning', {
      limit: this.optionsLimit,
      type: attributeType,
    });
  }

  get requiredAttributeText() {
    let attributeType = this.isConversationTicketType ? 'conversation' : 'ticket';
    let action = this.isConversationTicketType ? 'closing' : 'creating';

    return this.intl.t('settings.conversation-attributes.required-attribute-hint', {
      action,
      type: attributeType,
    });
  }

  get requiredToCreate() {
    if (this.isConversationTicketType) {
      return false;
    }

    return this.currentAttribute.requiredToCreate;
  }

  get requiredToClose() {
    return this.isConversationTicketType
      ? this.currentAttribute.required
      : this.requiredAttributeValue !== 'not_required';
  }

  get listOptionColumns() {
    let columns = [
      {
        label: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.ticket-attributes-modal-misc.list-option-name-column',
        ),
        valuePath: 'label',
      },
    ];

    if (this.appService.app.canUseAiConversationClassification && !this.isTicketDescriptor) {
      columns.push({
        label: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.ticket-attributes-modal-misc.list-option-description-column',
        ),
        valuePath: 'description',
        tooltip: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.ticket-attributes-modal-misc.list-option-description-tooltip',
        ),
      });
    }

    columns.push({ label: '', valuePath: 'actionButtons', width: '0' });
    return columns;
  }

  getRequiredAttributeValue(attribute) {
    if (attribute.requiredToCreate) {
      return 'required_to_create';
    } else {
      return 'not_required';
    }
  }

  @action
  addListOption(autofocus = true) {
    let newListOption = this.createListOptionObject();
    this.listOptions.pushObject(newListOption);
    if (autofocus) {
      this.focusFirstListOption();
    }
  }

  focusFirstListOption() {
    schedule('afterRender', () => {
      let emptyListOptionIndex = this.listOptions.findIndex((option) => isEmpty(option.label));
      let element = $(`[data-list-option-label="${emptyListOptionIndex}"]`);
      if (element) {
        scrollIntoViewIfNotVisible(element);
        element.focus();
      }
    });
  }

  @action
  removeListOption(index) {
    if (this.listOptions.length > 2) {
      this.listOptions.removeAt(index);
    }
  }

  @action
  updateType(type) {
    this.currentAttribute.listOptions.toArray().forEach((option) => option.rollbackAttributes());
    this.currentAttribute.dependentConditions
      .toArray()
      .forEach((condition) => condition.rollbackAttributes());
    this.listOptions = [];
    if (type === 'list') {
      if (this.listOptions.length === 0) {
        this.addListOption(false);
        this.addListOption(false);
      }
    }

    if (type === 'files') {
      this.currentAttribute.visibleToUsers = false;
      this.currentAttribute.requiredToCreateForUsers = false;
      this.setUserConversationAttachmentsEnabled();
    }
    this.dataType = type;
  }

  @action
  async archiveAttribute() {
    if (this.hasChanges) {
      await this.updateAttribute.perform(false);
    }
    this.args.archiveAttribute();
  }

  @action
  saveAttribute() {
    if (!this.validateListOptions()) {
      return;
    }

    if (this.editMode) {
      this.updateAttribute.perform();
    } else {
      this.createAttribute.perform();
    }
  }

  validateListOptions() {
    if (this.listOptions.any((option) => isEmpty(option.label))) {
      this.notificationsService.notifyError(
        this.intl.t(
          'settings.conversation-attributes.notifications.errors.all-options-must-have-values',
        ),
      );
      return false;
    }

    if (
      this.listOptions.any(
        (option) =>
          isPresent(option.description) &&
          (option.description.length < MIN_LIST_OPTION_DESCRIPTION_LENGTH ||
            option.description.length > MAX_LIST_OPTION_DESCRIPTION_LENGTH),
      )
    ) {
      this.notificationsService.notifyError(
        this.intl.t(
          'settings.conversation-attributes.notifications.errors.descriptions-between-range',
        ),
      );
      return false;
    }

    return true;
  }

  hasChanges() {
    let anyConditionDirty = this.currentAttribute?.dependentConditions.any(
      (condition) => condition.hasDirtyAttributes,
    );
    let anyOptionDirty = this.currentAttribute?.listOptions.any(
      (option) => option.hasDirtyAttributes,
    );
    let descriptorDirty = Object.entries(this.currentAttribute.changedAttributes()).some(
      ([key, value]) => {
        if (!Object.keys(this.descriptorDefaultAttrs).includes(key)) {
          return true;
        } else {
          return this.descriptorDefaultAttrs[key] !== value[1];
        }
      },
    );
    return descriptorDirty || anyConditionDirty || anyOptionDirty;
  }

  @action
  closeEditor() {
    if (this.hasChanges()) {
      this.showUnsavedChangesModal = true;
    } else {
      this.rollbackAttributeAndCloseModal();
    }
  }

  @action
  rollbackAttributeAndCloseModal() {
    if (this.currentAttribute.listOptions) {
      this.currentAttribute.listOptions.toArray().forEach((option) => option?.rollbackAttributes());
    }
    if (this.currentAttribute.dependentConditions) {
      this.currentAttribute.dependentConditions
        .toArray()
        .forEach((condition) => condition.rollbackAttributes());
    }
    this.currentAttribute.rollbackAttributes();
    this.args.onModalClose();
  }

  @action
  toggleIsRequired() {
    this.currentAttribute.required = !this.currentAttribute.required;
  }

  @action
  onVisibleToUsersChange() {
    if (this.currentAttribute.visibleToUsers && this.currentAttribute.requiredToCreateForUsers) {
      this.currentAttribute.requiredToCreateForUsers = false;
    }
  }

  @action
  onVisibleToTeammatesChange() {
    if (this.currentAttribute.visibleOnCreate && this.currentAttribute.requiredToCreate) {
      this.currentAttribute.requiredToCreate = false;
    }
  }

  @action
  updateVisibility(teamIds, visibility) {
    this.isVisibilityLimited = visibility;
    if (this.isVisibilityLimited) {
      this.currentAttribute.visibleToTeamIds = teamIds;
    } else {
      this.currentAttribute.visibleToTeamIds = null;
    }
  }

  @action
  requiredAttributeSelected(newValue) {
    this.requiredAttributeValue = newValue;

    if (this.requiredToCreate) {
      this.isVisibleOnCreate = true;
      this.isVisibleToUsers = true;
    }
  }

  @action sortListOptions() {
    let collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'case' });

    this.listOptions = this.listOptions.sort((a, b) => collator.compare(a.label, b.label));
  }

  @action
  reorderOptions(reordered) {
    this.listOptions = reordered;
  }

  @action
  listOptionUsedInConditions(index) {
    if (!this.args.canEditConditions) {
      return false;
    }

    let listOptionId = this.listOptions[index].id;
    let allConditions = this.store.peekAll('conversation-attributes/condition');
    return allConditions.any((condition) => {
      return (
        condition.controllingListOptionId === listOptionId ||
        condition.descriptorListOptionIds.includes(listOptionId)
      );
    });
  }

  @dropTask
  *updateAttribute(closeAfterUpdate = true) {
    let relationship = this.args.attributeToEdit?.relationship;
    let reference = this.args.attributeToEdit?.reference;

    try {
      if (relationship) {
        if (this.args.attributeToEdit?.isDestinationRelationshipAttribute) {
          this.relationshipCardinality = REVERSE_CARDINALITY_NAME_MAP[this.relationshipCardinality];
        }
        relationship.setProperties({
          cardinality: this.relationshipCardinality,
        });
      }

      if (reference) {
        reference.setProperties({
          referenceType: this.referenceType,
        });
      }

      this.currentAttribute.setProperties({
        listOptions: this.listOptions,
        required: this.requiredToClose,
        requiredToCreate: this.requiredToCreate,
        relatedObjectAttributeName: this.relatedObjectAttributeName,
        relatedObjectAttributeDescription: this.relatedObjectAttributeDescription,
        relationship: this.args.attributeToEdit.relationship,
        reference: this.args.attributeToEdit.reference,
        isClassificationAttribute: this.args.attributeToEdit.isClassificationAttribute,
      });

      yield this.currentAttribute.save();
      yield this.currentAttribute.dependentConditions.save();
      this.trackEvent('updated');
      if (closeAfterUpdate) {
        this.notificationsService.notifyConfirmation(
          this.intl.t(
            'settings.conversation-attributes.notifications.conversation-attribute-updated',
          ),
        );
        this.args.onModalClose();
      }
    } catch (error) {
      this.currentAttribute.rollbackAttributes();
      this.handleErrorNotification(error, 'update');
    }
  }

  handleErrorNotification(error, type = 'create') {
    if (error.jqXHR.status === 422) {
      if (error.jqXHR.responseJSON?.errors || error.jqXHR.responseJSON?.error_codes) {
        this.notificationsService.notifyError(this.errorMessage(error));
      } else {
        this.notificationsService.notifyError(
          this.intl.t(
            `settings.conversation-attributes.notifications.errors.ticket-attribute-${type}-name-duplicate`,
          ),
        );
      }
    } else if (error.jqXHR.status === 400) {
      this.notificationsService.notifyError(error.jqXHR.responseJSON.errors);
    } else {
      this.notificationsService.notifyError(
        this.intl.t(
          'settings.conversation-attributes.notifications.errors.ticket-attribute-generic-error',
        ),
      );
    }
  }

  errorMessage(error) {
    let errorMessage = error?.jqXHR?.responseJSON?.errors;
    if (Array.isArray(errorMessage)) {
      errorMessage = errorMessage.map((e) => e.message).join(', ');
    }

    let errorCodes = error?.jqXHR?.responseJSON?.error_codes;
    if (errorCodes) {
      for (let [field, error] of Object.entries(errorCodes)) {
        let messageKey = `settings.conversation-attributes.notifications.errors.${field}_${error}`;
        if (this.intl.lookup(messageKey)) {
          errorMessage = this.intl.t(messageKey);
        }
      }
    }

    return errorMessage;
  }

  @dropTask
  *createAttribute() {
    let ticketTypeId = this.isConversationTicketType ? null : this.args.ticketType.id;
    let descriptorsScopedByTicketType =
      ConversationAttributeDescriptor.peekAllAndMaybeLoad().filter(
        (d) => d.ticketTypeId === ticketTypeId,
      );

    let maxOrder = 0;
    if (descriptorsScopedByTicketType.length !== 0) {
      maxOrder = Math.max(...descriptorsScopedByTicketType.map((d) => d.order)) + 1;
    }

    let relationship;
    let reference;

    if (this.dataType === ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP) {
      reference = this.store.createRecord('objects/reference', {
        referencedObjectTypeIdentifier: this.referencedObjectTypeIdentifier,
        referenceType: this.referenceType,
      });
    }

    this.currentAttribute.setProperties({
      dataType: this.dataType,
      listOptions: this.listOptions,
      required: this.requiredToClose,
      requiredToCreate: this.requiredToCreate,
      order: maxOrder,
      ticketTypeId,
      relatedObjectAttributeName: this.relatedObjectAttributeName,
      relatedObjectAttributeDescription: this.relatedObjectAttributeDescription,
      isClassificationAttribute: this.isClassificationAttribute,
      relationship,
      reference,
    });

    try {
      yield this.currentAttribute.save();
      if (this.currentAttribute.dependentConditions) {
        this.currentAttribute.dependentConditions.toArray().forEach((condition) => {
          condition.set('controllingDescriptorId', this.currentAttribute.id);
        });
      }
      yield this.currentAttribute.dependentConditions.save();
      yield this.currentAttribute.relatedAttribute; // Cache related attribute;
      this.trackEvent('created');
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'settings.conversation-attributes.notifications.conversation-attribute-created',
        ),
      );
      this.args.onModalClose();
      if (this.args.onDescriptorCreated) {
        this.args.onDescriptorCreated(this.currentAttribute);
      }
    } catch (error) {
      this.handleErrorNotification(error, 'create');
    }
  }

  @dropTask
  *valueToDescriptionTask() {
    yield this.migrateAttributeValueToTickeDefaultDescription();
  }

  async migrateAttributeValueToTickeDefaultDescription() {
    let ticketTypeId = this.args.ticketType.id;
    await post(`/ember/inbox/ticket_types/${ticketTypeId}/migrate_attribute_to_description`, {
      app_id: this.appService.app.id,
      descriptor_id: this.args.attributeToEdit.id,
    });
    this.args.onModalClose();
    this.notificationsService.notifyConfirmation(
      this.intl.t('settings.ticket-data.migrate-field.confirmation'),
    );
  }

  trackEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'descriptor',
      place: 'settings',
      required: this.currentAttribute.required,
      name: this.currentAttribute.name,
      description: this.currentAttribute.description,
      listOptions: this.listOptions,
      multiline: this.currentAttribute.multiline,
      order: this.order,
      visibleToTeamIds: this.currentAttribute.visibleToTeamIds,
    });
  }
}
