/* import __COLOCATED_TEMPLATE__ from './list-descriptors.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class extends Component {
  // @Compact is an argument that is used to define if the edit button should appear over the name when hovering the att,
  // or if it should be on the right of the table. It also controls if we open a modal or sidedrawer when editing att.
  @service notificationsService;
  @service appService;
  @service intl;
  @service session;

  @tracked selectedAttribute;
  @tracked showDescriptorDetailsModal = false;
  @tracked showDescriptorArchiveModal = false;

  @tracked currentTab = 'live';
  @tracked conditionsEditorTargetAttribute = null;
  @tracked sideDrawerStartTab = null;

  get canEditConditions() {
    return this.args.conditionsAvailable;
  }

  get liveConversationAttributeDescriptors() {
    let liveConversationAttributeDescriptors = this.args.conversationAttributeDescriptors
      .rejectBy('archived')
      .reject(this.hasReferenceToUndefinedOrSystemDefinedObjectType);

    return liveConversationAttributeDescriptors.sortBy('order');
  }

  get archivedConversationAttributeDescriptors() {
    return this.args.conversationAttributeDescriptors
      .filterBy('archived')
      .reject(this.hasReferenceToUndefinedOrSystemDefinedObjectType);
  }

  get selectedConversationAttributeDescriptors() {
    if (this.currentTab === 'archived') {
      return this.archivedConversationAttributeDescriptors;
    } else {
      return this.liveConversationAttributeDescriptors;
    }
  }

  get liveTabActive() {
    return this.currentTab === 'live';
  }

  get dateLabel() {
    return this.liveTabActive ? 'Created' : 'Archived';
  }

  get dateValuePath() {
    return this.liveTabActive ? 'createdAt' : 'archivedAt';
  }

  get app() {
    return this.appService.app;
  }

  get noListAttributesAvailable() {
    if (this.liveConversationAttributeDescriptors.length < 2) {
      return false;
    }

    return !this.liveConversationAttributeDescriptors.some((attr) => attr.dataType === 'list');
  }

  get onlyAttributeOnTheList() {
    return this.liveConversationAttributeDescriptors.length <= 1;
  }

  get hasEnoughAttributes() {
    return this.liveConversationAttributeDescriptors.filter((attr) => !attr.isBuiltIn).length >= 2;
  }

  columnLabel(key) {
    return this.intl.t(`settings.conversation-attributes.table.${key}`);
  }

  get columns() {
    let isSortable = false;

    let tableColumns = [
      {
        label: this.columnLabel('name'),
        valuePath: 'nameLowercase',
        isSortable,
        minWidth: '200px',
      },
      {
        label: this.columnLabel('format'),
        valuePath: 'dataType',
        isSortable,
      },
    ];
    tableColumns.push({ label: this.dateLabel, valuePath: this.dateValuePath, isSortable });

    if (this.isConversationTicketType) {
      tableColumns.push({
        label: this.columnLabel('visibility'),
        valuePath: 'visibleTo',
        minWidth: '160px',
        width: '350px',
      });
    }

    if (this.liveTabActive) {
      tableColumns = [{ label: '', valuePath: 'order', width: '0' }, ...tableColumns];
    }

    if (this.isConversationTicketType) {
      tableColumns.push({ label: this.columnLabel('required'), valuePath: 'required', isSortable });
    } else {
      tableColumns.push({
        label: this.columnLabel('teammate-options'),
        valuePath: 'teammateOptions',
        labelCanWrap: true,
        minWidth: this.args.compact ? '130px' : undefined,
      });
      tableColumns.push({
        label: this.columnLabel('customer-options'),
        valuePath: 'customerOptions',
        labelCanWrap: true,
        minWidth: this.args.compact ? '120px' : undefined,
      });
    }

    if (this.canEditConditions) {
      tableColumns.push({
        label: this.columnLabel('conditions'),
        valuePath: 'conditionsCount',
        minWidth: '120px',
      });
    }

    if (this.app.canUseCollapseConversationDetails) {
      tableColumns.push({
        label: this.columnLabel('is-always-visible'),
        valuePath: 'isAlwaysVisible',
        isSortable,
      });
    }
    tableColumns.push({
      label: '',
      valuePath: 'actionButtons',
      width: '0',
    });

    return tableColumns;
  }

  get isConversationTicketType() {
    if (this.args.ticketType === undefined) {
      return true;
    }

    return this.args.ticketType.isConversationTicketType;
  }

  get emptyState() {
    return (
      this.args.emptyState || {
        icon: 'conversation',
        title: this.intl.t('settings.data.conversation-attributes.empty-state.title'),
      }
    );
  }

  humanReadableCombinedStatus(visible, required) {
    if (visible && required) {
      return 'Visible & required';
    } else if (visible) {
      return 'Visible';
    } else {
      return 'Not visible';
    }
  }

  @action
  editAttribute(attribute) {
    if (this.canEditConditions) {
      this.sideDrawerStartTab = null;
    }
    this.selectedAttribute = attribute;
    this.showDescriptorDetailsModal = true;
  }

  @action
  editAttributeConditions(attribute) {
    this.sideDrawerStartTab = 'conditions';
    this.selectedAttribute = attribute;
    this.showDescriptorDetailsModal = true;
  }

  @action
  archiveAttribute() {
    this.showDescriptorArchiveModal = true;
  }

  @action
  restoreAttribute(attribute) {
    this.unarchiveAttribute.perform(attribute);
  }

  @action
  switchTab(tab) {
    this.currentTab = tab;
  }

  @action
  selectLiveTab() {
    this.currentTab = 'live';
  }

  @action
  selectArchivedTab() {
    this.currentTab = 'archived';
  }

  @action
  closeModals() {
    this.showDescriptorDetailsModal = false;
    this.showDescriptorArchiveModal = false;
    this.conditionsEditorTargetAttribute = null;
  }

  @action
  saveConditions(attribute) {
    this.doSaveConditions.perform(attribute);
  }

  @task({ drop: true })
  *doSaveConditions(attribute) {
    try {
      yield attribute.conditions.save();
      this.notificationsService.notifyConfirmation(
        this.notification('conditions-success', { attribute: attribute.name }),
      );
      if (this.archivedConversationAttributeDescriptors.length === 0) {
        this.currentTab = 'live';
      }
      this.closeModals();
    } catch (error) {
      let errorMessage = error?.jqXHR?.responseJSON?.errors;
      let errorCodes = error?.jqXHR?.responseJSON?.error_codes;
      if (errorCodes) {
        for (let [field, error] of Object.entries(errorCodes)) {
          let messageKey = `settings.conversation-attributes.notifications.errors.${field}_${error}`;
          if (this.intl.lookup(messageKey)) {
            errorMessage = this.intl.t(messageKey);
          }
        }
      }

      attribute.rollbackAttributes();

      if (errorMessage) {
        this.notificationsService.notifyError(errorMessage);
      } else {
        this.notificationsService.notifyError(
          this.notification('conditions-failed', { attribute: attribute.name }),
        );
      }
    }
  }

  @task({ drop: true })
  *reorder(updatedOrder, draggedWrapper) {
    if (this.liveTabActive) {
      let oldOrder = [...this.liveConversationAttributeDescriptors];

      try {
        let orderChanged = this.updateOrderValues(updatedOrder);
        if (!orderChanged) {
          return;
        }
        yield draggedWrapper.save();

        this.notificationsService.notifyConfirmation(
          this.notification('reorder-success', { attribute: draggedWrapper.name }),
        );
      } catch (error) {
        this.notificationsService.notifyError(
          this.notification('reorder-failed', { attribute: draggedWrapper.name }),
        );
        this.updateOrderValues(oldOrder);
      }
    }
  }

  updateOrderValues(order) {
    let orderChanged = false;
    let numOfDefaultAttributes = this.args.conversationAttributeDescriptors
      .rejectBy('archived')
      .filter((d) => d.isBuiltIn).length;
    // default attributes don't get returned in updatedOrder, that's why we increment the each order by the number of default attributes
    // deafult attributes will always be at the top
    this.liveConversationAttributeDescriptors.forEach((d) => {
      if (order.indexOf(d) >= 0 && d.order !== order.indexOf(d) + numOfDefaultAttributes) {
        d.set('order', order.indexOf(d) + numOfDefaultAttributes);
        orderChanged = true;
      }
    });
    return orderChanged;
  }

  hasReferenceToUndefinedOrSystemDefinedObjectType(attribute, _index, _array) {
    return (
      isPresent(attribute.reference) &&
      (attribute.referencedObjectType === undefined ||
        attribute.referencedObjectType.isSystemDefined)
    );
  }

  @task({ drop: true })
  *unarchiveAttribute(attribute) {
    try {
      attribute.archived = false;
      attribute.order =
        Math.max(...this.liveConversationAttributeDescriptors.map((d) => d.order)) + 1;
      yield attribute.save();
      this.notificationsService.notifyConfirmation(
        this.notification('unarchive-success', { attribute: attribute.name }),
      );
      if (this.archivedConversationAttributeDescriptors.length === 0) {
        this.currentTab = 'live';
      }
    } catch (error) {
      attribute.rollbackAttributes();
      this.notificationsService.notifyError(
        this.notification('unarchive-failed', { attribute: attribute.name }),
      );
    }
  }

  @task({ drop: true })
  *toggleAttributeVisibility(attribute) {
    attribute.isAlwaysVisible = !attribute.isAlwaysVisible;
    yield attribute.save();

    if (attribute.isAlwaysVisible === true) {
      this.notificationsService.notifyConfirmation(
        this.notification('attribute-always-visible', { attribute: attribute.name }),
      );
    } else {
      this.notificationsService.notifyConfirmation(
        this.notification('attribute-hidden', { attribute: attribute.name }),
      );
    }
  }

  notification(translationKey, attributes) {
    return this.intl.t(
      `settings.conversation-attributes.notifications.${translationKey}`,
      attributes,
    );
  }
}
