/* import __COLOCATED_TEMPLATE__ from './custom-event-details.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import { computed, action } from '@ember/object';
import { capitalize } from '@ember/string';
import { readOnly, alias, not, equal, and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { applyFunction, ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import EventArchiver from 'embercom/lib/user-events/event-archiver';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import { USER_ROLE_PREDICATE, LEAD_ROLE_PREDICATE } from 'embercom/lib/default-predicates';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';

export default Component.extend({
  tagName: '',
  intercomEventService: service(),
  notificationsService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  appService: service(),
  intercomConfirmService: service(),
  app: readOnly('appService.app'),
  event: readOnly('model.event'),
  eventTracker: alias('model.eventTracker'),
  name: readOnly('event.name'),
  humanFriendlyName: readOnly('event.humanFriendlyName'),
  description: readOnly('event.description'),
  type: readOnly('event.type'),
  displayedType: applyFunction(capitalize, 'type'),
  createdAt: ternaryToProperty('event.created_at', 'event.created_at', 'app.created_at'),
  isArchived: readOnly('event.archived'),
  isNotArchived: not('isArchived'),
  isCustomData: readOnly('event.isCustomData'),
  isListDataType: equal('event.type', 'list'),
  isNotListDataType: not('isListDataType'),
  isEditableDataType: and('isCustomData', 'isNotListDataType', 'isNotArchived'),
  showCheckingDependentsModal: false,

  eventArchiver: computed('event', function () {
    return EventArchiver.create({
      appId: this.get('app.id'),
      event: this.event,
      onArchive: this.get('model.onArchive'),
    });
  }),

  eventPredicate: computed('event', function () {
    return {
      attribute: `user_event_summaries.${this.get('event.name')}.count`,
      comparison: 'known',
      type: 'user_event_integer',
      value: null,
    };
  }),

  leadListParam: computed('eventPredicate', function () {
    return this.getSegmentUrlParam(LEAD_ROLE_PREDICATE);
  }),

  userListParam: computed('eventPredicate', function () {
    return this.getSegmentUrlParam(USER_ROLE_PREDICATE);
  }),

  getSegmentUrlParam(rolePredicate) {
    let predicates = {
      predicates: [rolePredicate, this.eventPredicate],
    };
    let encodedPredicates = B64FilterParamEncoder.b64Encode(JSON.stringify(predicates));
    return `all:${encodedPredicates}`;
  },

  changeDescription: task(function* () {
    let event = this.event;
    try {
      yield this.eventArchiver.updateDescription();
      this.intercomEventService.trackEvent('added_description_to_data', {
        data_type: 'event',
      });
      this.event.set('_dirtyAttributes', []); // Manually reset dirty attributes since we're not using Ember model with typical update methods
    } catch (error) {
      this.notificationsService.notifyError(
        `Could not update the description for "${this.humanFriendlyName}"`,
      );
      event.revert();
    }
  }).drop(),

  unArchive: task(function* () {
    let event = this.event;
    let eventName = event.get('humanFriendlyName');
    let options = {
      title: 'Unarchive event',
      body: `Are you sure you want to unarchive ${eventName}?`,
      confirmButtonText: 'Unarchive event',
    };

    let confirm = yield this.intercomConfirmService.confirm(options);
    if (confirm) {
      try {
        yield this.eventArchiver.unarchive();
        this.get('model.onUnarchive')();
        this.notificationsService.notifyConfirmation(`${eventName} event unarchived`);
      } catch (error) {
        this.notificationsService.notifyError(`Could not unarchive ${eventName} event`);
      }
    }
  }).drop(),

  deleteEventTracker: task(function* () {
    try {
      yield this.eventTracker.destroyRecord();
      this.set('eventTracker', null);
      this.get('model.onDeleteEventTracker')();
      this.notificationsService.notifyConfirmation('Event tracker has been deleted.');
    } catch (e) {
      this.notificationsService.notifyError(
        "Something went wrong and we couldn't delete that event tracker.",
      );
    }
  }).drop(),

  close: action(function () {
    this.modalService.closeModal();
  }),

  actions: {
    changeDescription(newDescription) {
      this.event.set('description', newDescription);
    },

    archive() {
      this.set('showCheckingDependentsModal', true);
    },
  },
});
