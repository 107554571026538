/* import __COLOCATED_TEMPLATE__ from './import-table-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-private-routing-service */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { getOwner } from '@ember/application';
import { later } from '@ember/runloop';
import { computed } from '@ember/object';
import { or, alias, empty, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import openCenteredWindow from 'embercom/lib/open-centered-window';
import { selectFromObject } from '@intercom/pulse/lib/computed-properties';
import { capitalize } from '@ember/string';

const IMPORT_BUTTON_TEXT_MAPPINGS = {
  csv: 'settings.import-table-component.upload-csv',
  mailchimp: 'settings.import-table-component.import-from-mailchimp',
  mixpanel: 'settings.import-table-component.import-from-mixpanel',
};

export default Component.extend({
  realTimeEventService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  store: service(),
  intl: service(),

  get csvLabel() {
    return this.intl.t('settings.import-table-component.csv');
  },

  get mixpanelLabel() {
    return this.intl.t('settings.import-table-component.mixpanel');
  },

  get mailchimpLabel() {
    return this.intl.t('settings.import-table-component.mailchimp');
  },

  classNames: ['flex-auto', 'flex', 'flex-col'],

  didInsertElement() {
    this._super(...arguments);
    this.bindImportProgress();
  },

  willDestroyElement() {
    this._super(...arguments);
    this.unbindImportProgress();
  },

  bindImportProgress() {
    if (this.isBound) {
      return;
    }
    this.realTimeEventService.subscribeTopics([
      'csv-import-user-create-progress',
      'mailchimp-import-user-create-progress',
      'mixpanel-import-user-create-progress',
    ]);
    this.realTimeEventService.on('CsvImportUserCreateProgress', this, 'handleCsvImportProgress');
    this.realTimeEventService.on(
      'MailchimpImportUserCreateProgress',
      this,
      'handleMailchimpImportProgress',
    );
    this.realTimeEventService.on(
      'MixpanelImportUserCreateProgress',
      this,
      'handleMixpanelImportProgress',
    );
    this.set('isBound', true);
  },

  unbindImportProgress() {
    if (!this.isBound) {
      return;
    }
    this.realTimeEventService.off('CsvImportUserCreateProgress', this, 'handleCsvImportProgress');
    this.realTimeEventService.off(
      'MailchimpImportUserCreateProgress',
      this,
      'handleMailchimpImportProgress',
    );
    this.realTimeEventService.off(
      'MixpanelImportUserCreateProgress',
      this,
      'handleMixpanelImportProgress',
    );
    this.realTimeEventService.unsubscribeTopics([
      'csv-import-user-create-progress',
      'mailchimp-import-user-create-progress',
      'mixpanel-import-user-create-progress',
    ]);
    this.set('isBound', false);
  },

  handleCsvImportProgress(data) {
    let importModel = this.csvImports.findBy('id', data.id.toString());
    if (!importModel) {
      return;
    }

    if (data.complete) {
      this.store.findRecord('csv-import', data.id).then((csv) => {
        importModel.setProperties({
          importStatus: csv.get('importStatus'),
          importSummary: csv.get('importSummary'),
          importedRowFailureCount: csv.get('importedRowFailureCount'),
          shouldLinkToUserListUrl: true,
          rowCount: csv.get('rowCount'),
          recordType: csv.get('recordType'),
        });
        if (importModel.get('importedRowFailureCount') > 0) {
          importModel.set('shouldShowImportSummary', true);
        }
        this.decrementProperty('numberOfOpenCsvImportsForApp');
      });
    }

    this.handleImportProgress(data, importModel);
  },

  handleImportProgress(data, importModel) {
    if (!importModel) {
      return;
    }

    let progress = parseInt(data.progress, 10);

    if (progress < 100) {
      importModel.set('importProgress', data.progress);
    } else {
      importModel.set('importStatus', this.intl.t('settings.import-table-component.complete'));
      if (this.verifyCompletion) {
        this.verifyCompletion();
      }
    }
  },

  handleMailchimpImportProgress(data) {
    let importModel = this.mailchimpImports.findBy('id', data.id.toString());
    this.handleImportProgress(data, importModel);
  },

  handleMixpanelImportProgress(data) {
    let importModel = this.mixpanelImports.findBy('id', data.id.toString());
    this.handleImportProgress(data, importModel);
  },

  app: null,
  isFromNewSettings: null,
  selectedTypeExternalKey: null,
  selectedImportType: 'csv',

  selectedType: computed(
    'isFromNewSettings',
    'selectedTypeExternalKey',
    'selectedImportType',
    function () {
      return this.isFromNewSettings ? this.selectedTypeExternalKey : this.selectedImportType;
    },
  ),
  selectedTypeIsCsv: computed(
    'isFromNewSettings',
    'selectedKey',
    'selectedType',
    'selectedTypeExternalKey',
    function () {
      if (this.isFromNewSettings) {
        return this.selectedTypeExternalKey === 'csv';
      }
      return this.selectedType === 'csv';
    },
  ),
  selectedTypeIsMixpanel: computed(
    'isFromNewSettings',
    'selectedKey',
    'selectedType',
    'selectedTypeExternalKey',
    function () {
      if (this.isFromNewSettings) {
        return this.selectedTypeExternalKey === 'mixpanel';
      }
      return this.selectedType === 'mixpanel';
    },
  ),
  selectedTypeIsMailchimp: computed(
    'isFromNewSettings',
    'selectedKey',
    'selectedType',
    'selectedTypeExternalKey',
    function () {
      if (this.isFromNewSettings) {
        return this.selectedTypeExternalKey === 'mailchimp';
      }
      return this.selectedType === 'mailchimp';
    },
  ),
  selectedTypeIsCsvOrMailchimp: or('selectedTypeIsCsv', 'selectedTypeIsMailchimp'),
  importButtonTextMappings: IMPORT_BUTTON_TEXT_MAPPINGS,
  importButtonText: selectFromObject('importButtonTextMappings', 'selectedType'),
  model: null,
  csvImports: alias('model.csvImports'),
  mailchimpImports: alias('model.mailchimpImports'),
  mixpanelImports: alias('model.mixpanelImports'),
  selectedImports: computed(
    'selectedType',
    'csvImports',
    'mailchimpImports',
    'mixpanelImports',
    function () {
      return this.get(`${this.selectedType}Imports`);
    },
  ),
  numberOfOpenCsvImportsForApp: computed('csvImports', function () {
    return this.csvImports.filter((currentImport) => {
      return (
        currentImport.get('importStatus') !== 'Complete' &&
        currentImport.get('importStatus') !== 'Failed'
      );
    }).length;
  }),
  showCsvWarning: computed('selectedTypeIsCsv', 'numberOfOpenCsvImportsForApp', function () {
    return this.selectedTypeIsCsv && this.numberOfOpenCsvImportsForApp > 0;
  }),
  csvWarningText: computed('intl.locale', 'numberOfOpenCsvImportsForApp', function () {
    return this.intl.t('settings.import-table-component.being-processed-warning', {
      importsCopy: this.numberOfOpenCsvImportsForApp,
    });
  }),
  showNoImportsMessage: empty('selectedImports'),
  showLoadImports: computed('selectedType', function () {
    return this.get(`hasMore${capitalize(this.selectedType)}Imports`);
  }),

  pageNumber: 2,
  hasMoreCsvImports: readOnly('model.hasMoreCsvImports'),
  hasMoreMailchimpImports: false,
  hasMoreMixpanelImports: false,
  isLoading: false,

  openImportWindow(type) {
    let router = getOwner(this).lookup('router:main');
    let url = router.generate(`apps.app.import.${type}`);
    let window = openCenteredWindow(url, 800, 1280, 'Import');

    if (window) {
      this.waitForChildWindow(window);
      window.focus();
    }
  },

  waitForChildWindow(childWindow) {
    if (childWindow.closed) {
      if (window.importSuccessful) {
        this.store.findAll(`${this.selectedType}-import`).then((imports) => {
          this.set(`${this.selectedType}Imports`, imports);
        });
      }
    } else {
      later(this.waitForChildWindow.bind(this, childWindow), 1000);
    }
  },

  actions: {
    nextPage() {
      this.store
        .query(`${capitalize(this.selectedType)}-import`, { page: this.pageNumber })
        .then((page) => {
          this.incrementProperty('pageNumber');
          this.set(`hasMore${capitalize(this.selectedType)}Imports`, page.meta.pages_left);
        });
    },

    openImportSummary(importId) {
      let importObject = this.store.peekRecord(`${this.selectedType}-import`, importId);
      this.modalService.openModal('modal/import-summary-modal', importObject);
    },

    openImportInNewWindow() {
      let selectedType = this.selectedType;
      this.openImportWindow(selectedType);
    },
  },
});
