/* import __COLOCATED_TEMPLATE__ from './archive.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

const OBJECT_TYPE = 'object';

export default class Archive extends Component {
  @service store;
  @service notificationsService;
  @service router;
  @service intl;
  @service intercomEventService;
  @service appService;
  @service modelDataCacheService;

  get app() {
    return this.appService.app;
  }

  get archiveTypeTranslation() {
    if (this.args.archiveType === OBJECT_TYPE) {
      return this.intl.t('settings.custom-object-data.editor.object');
    } else {
      return this.intl.t('settings.custom-object-data.list.attribute-descriptors.attribute');
    }
  }

  @task({ drop: true })
  *archive() {
    try {
      yield this.args.archiveObject.archive();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.custom-object-data.modals.archive.notification-success', {
          type: this.archiveTypeTranslation,
        }),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'archive',
        object: 'archive',
        place: 'custom_object',
        archive_object_type: this.args.archiveType,
        archive_object_id: this.args.archiveObject.id,
      });
      this.args.onModalClose();
      this.app.updateLocalCache(this.modelDataCacheService);
      if (this.args.archiveType === OBJECT_TYPE) {
        this.router.transitionTo('apps.app.settings.data.custom-objects');
      }
    } catch (error) {
      console.error(error);
      this.store.unloadRecord(this.args.archiveObject);
      if (error.jqXHR.status === 400) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.error);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.custom-object-data.modals.archive.notification-failure', {
            type: this.archiveTypeTranslation,
          }),
        );
      }
    }
  }
}
