/* import __COLOCATED_TEMPLATE__ from './ticket-states-table.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type TicketSystemState } from 'embercom/objects/inbox/conversation';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type PALETTE from '@intercom/pulse/lib/palette';

type TabView = 'live' | 'archived';
interface Args {
  ticketCustomStatesPerSystemState: TicketCustomState[];
  icon: InterfaceIconName;
  systemState: TicketSystemState;
  iconColor: keyof typeof PALETTE;
  currentTab: TabView;
  onRestore?: (ticketCustomState: TicketCustomState) => void;
  newSettings?: boolean;
}

export default class TicketStatesTable extends Component<Args> {
  @service declare intl: IntlService;
  @service declare ticketStateService: TicketStateService;
  @tracked isEditingState = false;
  @tracked selectedCustomState: TicketCustomState | undefined = undefined;
  @tracked showArchiveTicketCustomStateModal = false;

  @action
  onEditCustomState(state: TicketCustomState) {
    this.selectedCustomState = state;
    this.isEditingState = true;
  }

  @action onArchiveTicketCustomStateModal() {
    this.showArchiveTicketCustomStateModal = true;
  }

  @action onArchivedState() {
    this.showArchiveTicketCustomStateModal = false;
    this.isEditingState = false;
  }

  @action
  onCloseEditModal() {
    this.isEditingState = false;
    this.selectedCustomState = undefined;
  }

  @action
  dependentTicketTypes(customState: TicketCustomState) {
    if (typeof customState !== 'undefined') {
      return this.ticketStateService
        .getTicketTypesForCustomState(customState)
        .filter((type) => !type.archived);
    } else {
      return [];
    }
  }

  @action
  displayedTicketTypes(customState: TicketCustomState) {
    return this.dependentTicketTypes(customState).slice(0, 2);
  }

  @action
  getTooltipTicketTypes(customState: TicketCustomState) {
    return this.dependentTicketTypes(customState).slice(2);
  }

  @action
  numberOfTicketsMissing(customState: TicketCustomState) {
    return this.dependentTicketTypes(customState).length - 2;
  }

  get ticketTypeRouteName() {
    return 'apps.app.settings.helpdesk.tickets.ticket-types.detail';
  }

  get columns() {
    return [
      {
        isVisible: true,
        label: this.intl.t('settings.ticket-states.list-ticket-states.admin-label-header'),
        valuePath: 'internal_label',
        width: '30%',
        minWidth: '300px',
      },
      {
        isVisible: true,
        label: this.intl.t('settings.ticket-states.list-ticket-states.customer-label-header'),
        tooltip: this.intl.t(
          'settings.ticket-states.list-ticket-states.customer-label-header-tooltip',
        ),
        valuePath: 'external_label',
        width: '30%',
        minWidth: '300px',
      },
      {
        isVisible: this.args.currentTab === 'live',
        label: this.intl.t('settings.ticket-states.list-ticket-states.ticket-types-label-header'),
        valuePath: 'ticket_types',
        width: '30%',
      },
      {
        isVisible: true,
        valuePath: 'edit_btn',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::TicketStates::TicketStatesTable': typeof TicketStatesTable;
  }
}
