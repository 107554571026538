/* import __COLOCATED_TEMPLATE__ from './dialog.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import type Owner from '@ember/owner';
import { action } from '@ember/object';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type MappingVisibility } from 'embercom/models/crm/attribute-mapping';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export interface Args {
  integrationCode: string;
  modalType: 'new' | 'edit' | 'hidden';
  sections: Array<SectionConfig>;
  onModalClose: (actionTaken: boolean) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export type SectionWidgetType =
  | 'match-objects'
  | 'map-attributes'
  | 'map-ticket-attributes'
  | 'map-public-ticket-attributes'
  | 'content-settings';

export interface StepConfig {
  object: string;
  widget: SectionWidgetType;
}

export interface SectionConfig {
  objectType: string;
  icon: InterfaceIconName;
  dependencies: Array<string>;
  active: boolean;
  frozen: boolean; // a section is frozen when it can't be toggled on/off but it's mappings can be edited
  disabled: boolean;
  comingSoon: boolean;
  steps: StepConfig[];
}

export class Section {
  objectType: string;
  icon: InterfaceIconName;
  done = false;
  @tracked active = false;
  frozen = false;
  disabled = false;
  comingSoon = false;
  steps: StepConfig[] = [];

  constructor(params: SectionConfig) {
    this.objectType = params.objectType;
    this.icon = params.icon;
    this.active = params.active;
    this.frozen = params.frozen;
    this.disabled = params.disabled;
    this.comingSoon = params.comingSoon;
    this.steps = params.steps;
  }
}

export default class Dialog extends Component<Signature> {
  @service declare intl: IntlService;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;
  // @ts-ignore
  @service declare notificationsService: NotificationsService;

  @service declare intercomEventService: $TSFixMe;

  documentationUrl =
    'https://www.intercom.com/help/en/articles/9307237-migrate-your-zendesk-ticket-user-and-organization-data';

  confirmDialogWarnings = [{ key: 'mappings', icon: 'alert-circle' }] as const;

  sections: Map<string, Section> = new Map<string, Section>();
  requirements = new Map<string, string[]>();
  dependants = new Map<string, string[]>();

  @tracked openSectionId: string | null = null;
  @tracked showConfirmDialog = false;
  @tracked showCancelEditDialog = false;
  @tracked showCloseWarningDialog = false;

  constructor(owner: Owner, args: Args) {
    super(owner, args);

    this.args.sections.forEach((sectionConfig) => {
      this.sections.set(sectionConfig.objectType, new Section(sectionConfig));

      this.requirements.set(sectionConfig.objectType, sectionConfig.dependencies);
      sectionConfig.dependencies?.forEach((dep: string) => {
        if (this.dependants.get(dep)) {
          this.dependants.get(dep)!.push(sectionConfig.objectType);
        } else {
          this.dependants.set(dep, [sectionConfig.objectType]);
        }
      });
    });

    taskFor(this.loadConfigurationTask).perform(this.args.integrationCode);
  }

  @action showFeedbackSurvey() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'show_feedback',
      migration_section: 'config_dialog',
      dialog_type: this.args.modalType,
    });
    window.Intercom('startSurvey', 40871747);
  }

  @action closeDialog() {
    this.showCloseWarningDialog = false;
    this.args.onModalClose(false);
  }

  @action toggleSwitch(name: string) {
    let enabled = !this.sections.get(name)?.active;
    this.activateSection(name, enabled);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'section_switch',
      switch_enabled: enabled,
      config_section: name,
      dialog_type: this.args.modalType,
    });
  }

  @action onOpenSectionChange(sectionId: string | null) {
    this.openSectionId = sectionId;
  }

  @action sectionDone(name: string) {
    let section = this.sections.get(name)!;
    section.done = true;
  }

  @action saveMappings() {
    let { objectTypes, identityTypes } = this.activeObjectTypes;
    let result: 'success' | 'error' = 'success';

    if (objectTypes.length > 0) {
      this.doSave(objectTypes, identityTypes);
    } else {
      this.notificationsService.notifyError(
        this.intl.t(this.translationKey('store-mappings.no-mappings-error')),
      );
      result = 'error';
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'save_draft',
      result,
      object_types: objectTypes,
      identity_types: identityTypes,
      dialog_type: this.args.modalType,
    });
  }

  @action startMigration() {
    let { objectTypes, identityTypes } = this.activeObjectTypes;

    this.importConfigurationService.startDataImport(objectTypes, identityTypes);
    this.showConfirmDialog = false;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'start_migration',
      object_types: objectTypes,
      identity_types: identityTypes,
      dialog_type: this.args.modalType,
    });

    this.args.onModalClose(true);
  }

  @action cancelEdit() {
    this.showCancelEditDialog = false;
    this.args.onModalClose(false);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'cancel_config',
      dialog_type: this.args.modalType,
    });
  }

  @action openMigrationGuide() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'migration_guide',
      dialog_type: this.args.modalType,
    });
    safeWindowOpen(this.documentationUrl, '_blank');
  }

  get canStartMigration() {
    return this.anyActiveSection && this.allSectionsReviewed;
  }

  get hasActiveMappings() {
    return this.anyActiveSection && this.hasMappingsWithStatus(['done']);
  }

  get showFooterSpinner() {
    return (
      this.importConfigurationService.isSaving ||
      this.importConfigurationService.isStartingDataImport
    );
  }

  sectionReviewed = (section: Section) => {
    if (this.sectionDisabled(section)) {
      return true;
    }

    if (section.objectType === 'ticket') {
      return (
        this.sectionValid(section, 'private', true) &&
        this.sectionValid(section, 'public', true) &&
        this.ticketSectionValid
      );
    }

    return this.sectionValid(section);
  };

  sectionValid = (
    section: Section,
    mappingVisibility: MappingVisibility = 'public',
    allowDefaults = false,
  ) => {
    return (
      this.sectionHasMappingsWithStatus(section, ['done'], mappingVisibility, allowDefaults) &&
      !this.sectionHasMappingsWithStatus(section, ['pending'], mappingVisibility)
    );
  };

  sectionDisabled = (section: Section) => {
    return section.disabled || !section.active;
  };

  get translationPrefix() {
    let base = `settings.data-import.${this.args.integrationCode}`;
    if (this.args.modalType === 'edit') {
      return `${base}.config-modal-edit`;
    }
    return `${base}.config-modal`;
  }

  translationKey = (path: string) => {
    let base = this.translationPrefix;
    return `${base}.${path}`;
  };

  translation = (path: string) => {
    return this.intl.t(this.translationKey(path));
  };

  hasTranslation = (path: string) => {
    return this.intl.exists(this.translationKey(path));
  };

  @dropTask *loadConfigurationTask(integrationCode: string): TaskGenerator<any> {
    yield this.importConfigurationService.loadConfiguration(integrationCode);
    let hasMappings = (name: string, visibility: MappingVisibility) =>
      this.importConfigurationService
        .listMappings(name, visibility)
        .some(
          (mapping) =>
            mapping.editable &&
            mapping.status === 'done' &&
            mapping.destinationObjectType !== 'ticket_type._default_',
        );
    for (let [name, section] of this.sections) {
      let active = section.frozen || hasMappings(name, 'public') || hasMappings(name, 'private');
      this.activateSection(name, active);
    }
  }

  private async doSave(objectTypes: string[], identityTypes: string[]) {
    await this.importConfigurationService.storeConfiguration(objectTypes, identityTypes);
    this.notificationsService.notifyConfirmation(this.translation('notifications.draft-saved'));
  }

  private get allSectionsReviewed() {
    for (let section of this.sections.values()) {
      if (!this.sectionReviewed(section)) {
        return false;
      }
    }
    return true;
  }

  private hasMappingsWithStatus(statusList: string[]) {
    for (let [name, section] of this.sections) {
      let allowDefaults = name === 'ticket';
      if (
        this.sectionHasMappingsWithStatus(section, statusList, 'public', allowDefaults) ||
        this.sectionHasMappingsWithStatus(section, statusList, 'private', allowDefaults)
      ) {
        return true;
      }
    }
    return false;
  }

  private sectionHasMappingsWithStatus(
    section: Section,
    statusList: string[],
    mappingVisibility: MappingVisibility = 'public',
    allowDefaults = false,
  ) {
    let mappings = this.importConfigurationService.listMappings(
      section.objectType,
      mappingVisibility,
    );
    return mappings.some(
      (mapping) => statusList.includes(mapping.status) && (allowDefaults || mapping.editable),
    );
  }

  private activateSection(name: string, activate: boolean) {
    let section = this.sections.get(name);
    if (section === undefined || section.active === activate) {
      return;
    }

    section.active = activate;
    if (activate) {
      this.requirements.get(name)?.forEach((f: string) => this.activateSection(f, activate));
    } else {
      if (this.openSectionId === name) {
        this.openSectionId = null;
      }

      this.dependants.get(name)?.forEach((f: string) => this.activateSection(f, activate));
    }
  }

  private get ticketSectionValid() {
    if (!this.sections.get('ticket')?.active) {
      return true;
    }

    return (
      !!this.importConfigurationService.privateTicketTypeId &&
      !!this.importConfigurationService.publicTicketTypeId &&
      !!this.importConfigurationService.fallbackAdminId
    );
  }

  private get anyActiveSection() {
    for (let section of this.sections.values()) {
      if (section.active) {
        return true;
      }
    }
    return false;
  }

  private get activeObjectTypes() {
    let activeSections = Array.from(this.sections.values()).filter(
      (section: Section) => section.active,
    );

    let objectTypes = activeSections.map((section: Section) => section.objectType);

    let identityTypes = activeSections
      .map((section) => section.steps)
      .flat()
      .filter((step) => step.widget === 'match-objects')
      .map((step) => step.object)
      .filter((value, index, array) => array.indexOf(value) === index); // unique

    return {
      objectTypes,
      identityTypes,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::Dialog': typeof Dialog;
    'settings/data-import/modal/dialog': typeof Dialog;
  }
}
