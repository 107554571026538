/* import __COLOCATED_TEMPLATE__ from './match-objects-widget.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type StepConfig } from './dialog';
import { action } from '@ember/object';
import type Owner from '@ember/owner';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator, type TaskInstance } from 'ember-concurrency';
import type IdentityMapping from 'embercom/models/crm/identity-mapping';

export interface Args {
  integrationCode: string;
  translationPrefix: string;
  step: StepConfig;
  onDone: () => void;
  onBack: () => void;
  hasPrevStep: () => boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class MatchObjectsWidget extends Component<Signature> {
  @service declare intl: IntlService;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  @tracked task!: TaskInstance<any>;

  constructor(owner: Owner, args: Args) {
    super(owner, args);
  }

  loadTableData = (objectType: string) => {
    // to force auto-tracking of the returned TaskInstance, to allow for loading state
    this.task = taskFor(this.loadCrmObjectTask).perform(objectType);
    return this.task;
  };

  get tableData() {
    return this.importConfigurationService.listIdentityMappings(this.args.step.object);
  }

  @dropTask *loadCrmObjectTask(objectType: string): TaskGenerator<any> {
    yield this.importConfigurationService.loadCrmObjects(this.args.integrationCode, objectType);
  }

  @action onDone() {
    this.args.onDone();
  }

  crmObjectLabel = (mapping: IdentityMapping) => {
    let object = this.importConfigurationService.matchingCrmObject(mapping);
    if (object !== undefined) {
      return object.name;
    }
    return '';
  };

  get translationPrefix() {
    return `${this.args.translationPrefix}.steps.${this.args.step.object}`;
  }

  translation = (path: string) => {
    return this.intl.t(`${this.translationPrefix}.${path}`);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::MatchObjectsWidget': typeof MatchObjectsWidget;
    'settings/data-import/modal/match-objects-widget': typeof MatchObjectsWidget;
  }
}
