/* import __COLOCATED_TEMPLATE__ from './color-picker.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  value?: string;
  setColor?: (color: string) => void;
  colpickLayout?: string;
  colorOptions?: string[];
  class?: string;
  disabled?: boolean;
  baseColor?: string;
}

export default class ColorPicker extends Component<Args> {
  @tracked color = this.args.value ?? '#FFFFFF';

  get value(): string {
    return this.color;
  }

  set value(value: string) {
    this.color = value;
    this.setColor(value);
  }

  get colorWithHashtag(): string {
    if (!this.color) {
      return '#FFFFFF';
    } else if (this.color.startsWith('#')) {
      return this.color;
    }
    return `#${this.color}`;
  }

  @action
  updateValue(color: string): void {
    this.value = color;
    this.setColor(color);
  }

  @action
  setColor(color: string): void {
    if (color !== this.args.value) {
      this.args.setColor?.(`#${color}`);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Appearance::ColorPicker': typeof ColorPicker;
    'settings/appearance/color-picker': typeof ColorPicker;
  }
}
