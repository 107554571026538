/* import __COLOCATED_TEMPLATE__ from './add-with-entri.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  type EntriDnsRecord,
  type EntriConnectConfig,
} from 'embercom/services/entri-connect-service';
import type EntriConnectService from 'embercom/services/entri-connect-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  domainSettings: any;
}

export default class AddWithEntriComponent extends Component<Args> {
  @service declare entriConnectService: EntriConnectService;
  @tracked token: string | null = null;
  @tracked canBeAutoConfigured = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.checkDomainAndLoadLibrary();
  }

  @action
  openEntriModal(): void {
    let config = this.config;
    (window as any).entri.showEntri(config);
  }

  @action
  async generateLink(): Promise<void> {
    await taskFor(this.entriConnectService.generateLink).perform(
      this.dkimSettings.domain,
      this.dnsRecords,
    );
  }

  @action
  async checkDomainAndLoadLibrary(): Promise<void> {
    this.canBeAutoConfigured = await taskFor(this.entriConnectService.checkDomain).perform(
      Number(this.dkimSettings?.id) ?? 0,
      Number(this.customBounceSettings?.id) ?? 0,
    );
    if (this.canBeAutoConfigured) {
      await taskFor(this.entriConnectService.loadEntriLibrary).perform();
    }
  }

  get isCheckingDomain() {
    return taskFor(this.entriConnectService.checkDomain).isRunning;
  }

  get isLoadingLibrary() {
    return taskFor(this.entriConnectService.loadEntriLibrary).isRunning;
  }

  get isGeneratingLink() {
    return taskFor(this.entriConnectService.generateLink).isRunning;
  }

  get shareableLink() {
    return this.entriConnectService.link;
  }

  get dkimSettings() {
    return this.args.domainSettings.dkimRecord;
  }

  get customBounceSettings() {
    return this.args.domainSettings.customBounceSetting;
  }

  get config(): EntriConnectConfig {
    return {
      applicationId: 'intercom', // this is always the same
      token: this.entriConnectService.token,
      prefilledDomain: this.dkimSettings?.domain,
      whiteLabel: this.entriConnectService.whitelabelOptions,
      dnsRecords: this.dnsRecords,
    };
  }

  private get dnsRecords(): EntriDnsRecord[] {
    return [
      {
        type: 'CNAME',
        host: this.dkimSettings.domainForCustomerDnsRecord,
        value: this.dkimSettings.domainForIntercomDnsRecord,
        ttl: 300,
      },
      {
        type: 'CNAME',
        host: this.customBounceSettings.host,
        value: this.customBounceSettings.value,
        ttl: 300,
      },
      {
        type: 'TXT',
        host: `_dmarc.${this.dkimSettings?.domain}`,
        value: 'v=DMARC1; p=none',
        ttl: 300,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::Domains::Manage::AddWithEntri': typeof AddWithEntriComponent;
    'settings/domains/manage/add-with-entri': typeof AddWithEntriComponent;
  }
}
