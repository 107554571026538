/* import __COLOCATED_TEMPLATE__ from './map-attributes-widget.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  integrationCode: string;
  sectionName: string;
  section: any;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const MapAttributesWidget = templateOnlyComponent<Signature>();
export default MapAttributesWidget;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::MapAttributesWidget': typeof MapAttributesWidget;
    'settings/data-import/modal/map-attributes-widget': typeof MapAttributesWidget;
  }
}
