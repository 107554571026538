/* import __COLOCATED_TEMPLATE__ from './content-settings-widget.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type Owner from '@ember/owner';
import { action } from '@ember/object';

interface Args {
  integrationCode: string;
  sectionName: string;
  section: any;
  onDone: () => void;
  onBack: () => void;
  hasPrevStep: () => boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class ContentSettingsWidget extends Component<Signature> {
  @service declare intl: IntlService;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  attachInlineImagesSetting: boolean;
  migrateAttachmentsSetting: boolean;

  constructor(owner: Owner, args: Args) {
    super(owner, args);
    this.attachInlineImagesSetting = this.importConfigurationService.attachInlineImagesSetting;
    this.migrateAttachmentsSetting = this.importConfigurationService.migrateAttachmentsSetting;
  }

  @action toggleAttachInlineImagesSetting() {
    this.importConfigurationService.setAttachInlineImagesSetting(!this.attachInlineImagesSetting);
  }

  @action toggleMigrateAttachmentsSetting() {
    this.importConfigurationService.setMigrateAttachmentsSetting(!this.migrateAttachmentsSetting);
  }

  @action onDone() {
    this.args.onDone();
  }

  translation = (path: string) => {
    return this.intl.t(this.translationKey(path));
  };

  private translationKey = (path: string) => {
    return `settings.data-import.${this.args.integrationCode}.config-modal.accordion.sections.ticket.steps.content.${path}`;
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::ContentSettingsWidget': typeof ContentSettingsWidget;
    'settings/data-import/modal/content-settings-widget': typeof ContentSettingsWidget;
  }
}
