/* import __COLOCATED_TEMPLATE__ from './getting-started.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export const DESCRIPTOR_TEMPLATES = {
  type: {
    name: 'Type',
    description: 'Type of the conversation',
    dataType: 'list',
    listOptionLabels: ['Question', 'Bug', 'Feature request'],
  },
  productArea: {
    name: 'Product Area',
    description: 'Product Area of the conversation',
    dataType: 'list',
    listOptionLabels: ['Home', 'Mobile', 'Settings'],
  },
  urgency: {
    name: 'Urgency',
    description: 'Urgency of the conversation',
    dataType: 'list',
    listOptionLabels: ['High', 'Normal', 'Low'],
  },
};
export default class extends Component {
  @tracked showDescriptorDetailsModal = false;
  @tracked descriptorTemplateParams;
  @service session;

  get canUseConversationConditionalAttributesBeta() {
    return this.session.workspace.canUseConversationConditionalAttributesBeta;
  }

  @action
  onTemplateClick(type) {
    this.descriptorTemplateParams = DESCRIPTOR_TEMPLATES[type];
    this.showDescriptorDetailsModal = true;
  }

  @action
  onClose() {
    this.showDescriptorDetailsModal = false;
    this.descriptorTemplateParams = null;
  }

  get attributeType() {
    let isTicketType =
      isPresent(this.args.ticketType) && !this.args.ticketType.isConversationTicketType;
    return isTicketType ? 'ticket' : 'conversation';
  }

  get isConversationAttribute() {
    return this.attributeType === 'conversation';
  }

  get archivedTicketType() {
    return this.attributeType === 'ticket' && this.args.ticketType.archived;
  }

  get title() {
    return this.isConversationAttribute
      ? 'Get started with conversation data'
      : 'Create attributes for this ticket type';
  }

  get description() {
    return this.isConversationAttribute
      ? 'Collect structured data about a conversation by creating a custom attribute. ' +
          'You can use that data across Messenger, Inbox, Reporting, and more to create a ticketing workflow to manage complex conversations more efficiently in Intercom.'
      : 'Attributes are pieces of information that can be entered when creating a ticket.';
  }
}
