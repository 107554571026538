/* import __COLOCATED_TEMPLATE__ from './info.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Admin from 'embercom/models/admin';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { OUTBOUND_EDITOR_ROUTES } from 'embercom/models/outbound/content-wrapper';

export default class Info extends Component {
  @service store;
  @service outboundHomeService;

  get messagesQueryParams() {
    return [...this.outboundHomeService.contentToDisplay, objectTypes.series].map(
      (c) => objectNames[c],
    );
  }

  get dependentMacros() {
    return this.args.dependencies.dependentMacros.map((macro) => {
      let displayName = macro.name;
      if (macro.visibility === 'owner') {
        let admin = Admin.peekAndMaybeLoad(this.store, macro.admin_id);
        displayName = `(${admin.nameOrEmail}) ${macro.name}`;
      }
      return {
        ...macro,
        displayName,
      };
    });
  }

  get dependentRulesetsWithRoutes() {
    return this.args.dependencies?.dependentRulesets?.map((ruleset) => {
      return {
        ...ruleset,
        route: OUTBOUND_EDITOR_ROUTES[objectNames[ruleset.object_type]] || 'apps.app.outbound',
        is_series: ruleset.object_type === objectTypes.series,
      };
    });
  }
}
