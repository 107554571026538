/* import __COLOCATED_TEMPLATE__ from './attribute-descriptor.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import {
  ATTRIBUTE_DESCRIPTOR_TYPE_STRING,
  ATTRIBUTE_DESCRIPTOR_TYPE_LIST,
  ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP,
  ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_MAP,
  ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP,
} from 'embercom/models/custom-objects/constants/attribute-descriptor-types';
import { REVERSE_CARDINALITY_NAME_MAP } from 'embercom/models/custom-objects/constants/relationship-cardinalities';
import { REFERENCES_ONE, REFERENCES_MANY } from 'embercom/models/objects/constants/reference-types';

export default class AttributeDescriptor extends Component {
  @tracked name = '';
  @tracked description = '';
  @tracked dataType = ATTRIBUTE_DESCRIPTOR_TYPE_STRING;
  @tracked isRequired = false;
  @tracked listOptions = [];
  @tracked relatedObjectTypeIdentifier;
  @tracked relatedObjectAttributeName;
  @tracked relatedObjectAttributeDescription;
  @tracked relationshipCardinality = this.args.editingAttributeDescriptor?.cardinality;
  @tracked showEditCardinalityWarning = false;
  @tracked showEditReferenceTypeWarning = false;
  @tracked referenceType =
    this.args.editingAttributeDescriptor?.reference?.referenceType || REFERENCES_MANY;
  @tracked referencedObjectTypeIdentifier;

  @service store;
  @service notificationsService;
  @service intl;
  @service appService;
  @service customObjectsService;
  @service intercomEventService;
  @service modelDataCacheService;

  get app() {
    return this.appService.app;
  }

  constructor() {
    super(...arguments);

    if (this.editMode) {
      this.name = this.args.editingAttributeDescriptor.name;
      this.description = this.args.editingAttributeDescriptor.description;
      this.dataType = this.args.editingAttributeDescriptor.dataType;
      this.isRequired = this.args.editingAttributeDescriptor.required;
      this.listOptions = this.args.editingAttributeDescriptor.unArchivedListOptions;
      this.referencedObjectTypeIdentifier =
        this.args.editingAttributeDescriptor?.reference?.referencedObjectTypeIdentifier;
    }
  }

  get editMode() {
    return isPresent(this.args.editingAttributeDescriptor);
  }

  get titleLabel() {
    if (this.editMode) {
      return 'settings.custom-object-data.modals.attribute-descriptor.edit-title';
    } else {
      return 'settings.custom-object-data.modals.attribute-descriptor.create-title';
    }
  }

  get isValid() {
    return this.name.trim() !== '' && this.isReferenceValid;
  }

  get isReferenceValid() {
    return (
      this.dataType !== ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP ||
      isPresent(this.referencedObjectTypeIdentifier)
    );
  }

  get typeDropdownItems() {
    return Object.keys(ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_MAP).map((descriptorType) => {
      return {
        text: ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_MAP[descriptorType],
        value: descriptorType,
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP[descriptorType],
      };
    });
  }

  @action
  updateType(type) {
    this.listOptions = [];
    if (type === ATTRIBUTE_DESCRIPTOR_TYPE_LIST) {
      this.addListOption();
      this.addListOption();
    }
    this.dataType = type;
  }

  @action
  onReferenceTypeToggle() {
    let existingReferenceType = this.args.editingAttributeDescriptor?.reference?.referenceType;

    this.referenceType = this.referenceType === REFERENCES_MANY ? REFERENCES_ONE : REFERENCES_MANY;
    this.showEditReferenceTypeWarning =
      isPresent(existingReferenceType) && this.referenceType !== existingReferenceType;
  }

  get saveSuccessLabel() {
    if (this.editMode) {
      return 'settings.custom-object-data.modals.attribute-descriptor.notification-edit-success';
    } else {
      return 'settings.custom-object-data.modals.attribute-descriptor.notification-create-success';
    }
  }

  get saveFailureLabel() {
    if (this.editMode) {
      return 'settings.custom-object-data.modals.attribute-descriptor.notification-edit-failure';
    } else {
      return 'settings.custom-object-data.modals.attribute-descriptor.notification-create-failure';
    }
  }

  @task({ drop: true })
  *saveAttributeDescriptor() {
    if (!this.listOptions.every((option) => option.label.length > 0)) {
      this.notificationsService.notifyError(
        this.intl.t(
          'settings.custom-object-data.modals.attribute-descriptor.notification-create-list-attribute-failure',
        ),
      );
      return;
    }

    let attributeDescriptor;
    let relationship;
    let reference;

    if (this.editMode) {
      this.args.editingAttributeDescriptor.description = this.description;
      this.args.editingAttributeDescriptor.relatedObjectAttributeDescription =
        this.relatedObjectAttributeDescription;
      this.args.editingAttributeDescriptor.required = this.isRequired;
      this.args.editingAttributeDescriptor.listOptions = this.listOptions;

      if (this.args.editingAttributeDescriptor.relationship) {
        if (this.args.editingAttributeDescriptor.isDestinationRelationshipAttribute) {
          this.args.editingAttributeDescriptor.relationship.cardinality =
            REVERSE_CARDINALITY_NAME_MAP[this.relationshipCardinality];
        } else {
          this.args.editingAttributeDescriptor.relationship.cardinality =
            this.relationshipCardinality;
        }
      }

      if (this.args.editingAttributeDescriptor.reference) {
        this.args.editingAttributeDescriptor.reference.referenceType = this.referenceType;
      }

      attributeDescriptor = this.args.editingAttributeDescriptor;
    } else {
      if (this.dataType === ATTRIBUTE_DESCRIPTOR_TYPE_RELATIONSHIP) {
        reference = this.store.createRecord('objects/reference', {
          referencedObjectTypeIdentifier: this.referencedObjectTypeIdentifier,
          referenceType: this.referenceType,
        });
      }

      attributeDescriptor = this.store.createRecord('objects/attribute-descriptor', {
        name: this.name,
        objectType: this.args.customObject,
        description: this.description,
        relatedObjectAttributeName: this.relatedObjectAttributeName,
        relatedObjectAttributeDescription: this.relatedObjectAttributeDescription,
        dataType: this.dataType,
        required: this.isRequired,
        listOptions: this.listOptions,
        reference,
      });
    }

    try {
      yield attributeDescriptor.save();
      this.notificationsService.notifyConfirmation(this.intl.t(this.saveSuccessLabel));
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: this.editMode ? 'edit_attribute' : 'create_attribute',
        place: 'custom_object',
        attribute_descriptor_id: attributeDescriptor.id,
        relationship_id: relationship?.id,
        reference_id: reference?.id,
      });

      if (!this.editMode) {
        yield attributeDescriptor.relatedAttribute; // Cache related attribute;
      }

      this.app.updateLocalCache(this.modelDataCacheService);
      this.args.onModalClose();
    } catch (error) {
      if (!this.editMode) {
        attributeDescriptor.rollbackAttributes();
      }
      if (error.jqXHR.status === 400) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.error);
      } else {
        this.notificationsService.notifyError(this.intl.t(this.saveFailureLabel));
      }
    }
  }

  @action
  addListOption() {
    let newListOption = this.createListOptionObject();
    this.listOptions.pushObject(newListOption);
  }

  @action
  removeListOption(index) {
    if (this.listOptions.length > 2) {
      this.listOptions.removeAt(index);
    }
  }

  createListOptionObject(label = '') {
    return this.store.createRecord('conversation-attributes/list-option', { label });
  }
}
