/* import __COLOCATED_TEMPLATE__ from './unblock-blocked-user-modal.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { not, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';

export default Component.extend({
  tagName: '',
  notificationsService: service(),
  intl: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  isUnblocking: readOnly('_unblockUser.isRunning'),
  isNotUnblocking: not('isUnblocking'),

  _unblockUser: task(function* (id, appId) {
    try {
      yield $.ajax({
        url: '/ember/blocked_users/unblock',
        data: JSON.stringify({
          id,
          app_id: appId,
        }),
        type: 'POST',
        contentType: 'application/json',
        dataType: 'JSON',
      });
      this.model.list.removeObject(this.model);
      this.model.unloadRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.settings.blocked-people.notifications.unblock.success', {
          userNameOrEmail: this.model.nameOrEmail,
        }),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('components.settings.blocked-people.notifications.unblock.error'),
      );
    } finally {
      this.closeModal();
    }
  }).drop(),

  closeModal: action(function () {
    this.modalService.closeModal();
  }),

  actions: {
    cancel() {
      if (this.isNotUnblocking) {
        this.closeModal();
      }
    },
    unblockUser() {
      let id = this.get('model.id');
      let appId = this.get('model.app_id');

      this._unblockUser.perform(id, appId);
    },
  },
});
