/* import __COLOCATED_TEMPLATE__ from './required-attributes-table.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import { type MappingVisibility } from 'embercom/models/crm/attribute-mapping';
import type AttributeMapping from 'embercom/models/crm/attribute-mapping';
import type Attribute from 'embercom/models/attribute';
import { ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP } from 'embercom/models/custom-objects/constants/attribute-descriptor-types';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface Args {
  integrationCode: string;
  sectionName: string;
  mappingVisibility: MappingVisibility;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class RequiredAttributesTable extends Component<Signature> {
  @service declare intl: IntlService;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  get sectionData() {
    let ticketTypeId = this.importConfigurationService.getTicketTypeId(this.args.mappingVisibility);
    if (ticketTypeId === null) {
      return [] as AttributeMapping[];
    }

    return this.importConfigurationService.listRequiredTicketMappings(
      ticketTypeId,
      this.args.mappingVisibility,
    );
  }

  destinationAttribute = (mapping: AttributeMapping): Attribute | undefined => {
    return this.importConfigurationService.matchingDestinationAttribute(mapping);
  };

  destinationAttributeIcon(attribute: Attribute | undefined): InterfaceIconName {
    if (attribute === undefined) {
      return 'alert';
    }
    let key = attribute.type as keyof typeof ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP;
    return ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP[key] as InterfaceIconName;
  }

  destinationAttributeLabel = (attribute: Attribute | undefined, mapping: AttributeMapping) => {
    if (attribute === undefined) {
      return mapping.destinationAttributePath;
    }
    return attribute.name || attribute.identifier;
  };

  translation = (path: string) => {
    return this.intl.t(this.translationKey(path));
  };

  private translationKey = (path: string) => {
    return `settings.data-import.${this.args.integrationCode}.config-modal.accordion.page.${path}`;
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::RequiredAttributesTable': typeof RequiredAttributesTable;
    'settings/data-import/modal/required-attributes-table': typeof RequiredAttributesTable;
  }
}
