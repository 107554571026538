/* import __COLOCATED_TEMPLATE__ from './object-type.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';

export default class ObjectType extends Component {
  @service store;
  @service notificationsService;
  @service router;
  @service intl;
  @service intercomEventService;
  @service appService;
  @service modelDataCacheService;

  @tracked name = '';

  get app() {
    return this.appService.app;
  }

  get isValid() {
    return this.name.trim() !== '';
  }

  @task({ drop: true })
  *createObjectType() {
    let objectType = this.store.createRecord('custom-objects/object-type', {
      name: this.name,
    });

    try {
      yield objectType.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.custom-object-data.modals.object-type.notification-success'),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: 'create_new_object',
        place: 'custom_object',
      });
      this.app.customObjectTypes.addObject(objectType);
      this.app.updateLocalCache(this.modelDataCacheService);
      this.args.onModalClose();
      this.router.transitionTo('apps.app.settings.data.custom-objects.edit', objectType.identifier);
    } catch (error) {
      this.store.unloadRecord(objectType);
      if (error.jqXHR.status === 400) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.error);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.custom-object-data.modals.object-type.notification-failure'),
        );
      }
    }
  }
}
