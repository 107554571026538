/* import __COLOCATED_TEMPLATE__ from './select-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import type AttributeMapping from 'embercom/models/crm/attribute-mapping';
import type { MappingVisibility } from 'embercom/models/crm/attribute-mapping';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type IntercomAttribute from 'embercom/models/attribute';
import {
  ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP,
  type ATTRIBUTE_DESCRIPTOR_TYPES,
  ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_TRANSLATION_KEY_MAP,
} from 'embercom/models/custom-objects/constants/attribute-descriptor-types';
import { captureException } from 'embercom/lib/sentry';
import { isPresent } from '@ember/utils';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntercomAttributeUpdaterService from 'embercom/services/data/intercom-attribute-updater-service';
import type Attribute from 'embercom/models/crm/attribute';

interface Args {
  mappingVisibility: MappingVisibility;
  sectionName: string;
  integrationCode: string;
  mapping: AttributeMapping;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const supportedSectionsForAttributeCreation = ['contact', 'company'];

class DatatypeUnsupportedError extends Error {}

export default class SelectAttribute extends Component<Signature> {
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;
  @service declare intl: IntlService;
  @service declare modalService: any;
  @service declare notificationsService: any;

  @service('data/intercom-attribute-updater-service')
  declare attributeUpdater: IntercomAttributeUpdaterService;

  @tracked hasAvailableTypes = isPresent(this.availableTypes);
  @tracked loading = false;

  defaultIcon: InterfaceIconName | undefined = 'unsubscribed';

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (!this.hasAvailableTypes) {
      this.onSkipItem();
    }
  }

  get availableTypes() {
    return this.importConfigurationService.getMappableTypes(this.args.mapping);
  }

  get mappingOptions() {
    let mappingAlreadyUsedProps = {
      isDisabled: true,
      tooltipText: this.intl.t(this.translationKey('attribute-already-in-use')),
    };

    let mappingUnassignableProps = {
      isDisabled: true,
      tooltipText: this.intl.t(this.translationKey('attribute-unassignable')),
    };

    return this.importConfigurationService
      .listMappingAlternatives(this.args.sectionName, this.args.mapping)
      .map((attr: IntercomAttribute) => {
        let attrTypeKey = attr.type as keyof typeof ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP;
        let selectItem = {
          text: attr.humanFriendlyName || attr.identifier,
          value: attr,
          icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP[attrTypeKey],
        };

        if (this.importConfigurationService.attributeUnassignable(this.args.sectionName, attr)) {
          return { ...selectItem, ...mappingUnassignableProps };
        }

        let alreadyAssigned =
          attr.identifier !== this.args.mapping.destinationAttributePath &&
          this.importConfigurationService.attributeAssigned(
            this.args.sectionName,
            attr,
            this.args.mappingVisibility,
          );

        if (alreadyAssigned) {
          return { ...selectItem, ...mappingAlreadyUsedProps };
        }

        return selectItem;
      });
  }

  @action onSelectItem(attr: IntercomAttribute) {
    this.args.mapping.destinationAttributePath = attr.identifier;
    this.args.mapping.status = 'done';

    if (this.args.sectionName === 'ticket') {
      this.args.mapping.destinationObjectType = `ticket_type.${this.importConfigurationService.getTicketTypeId(
        this.args.mappingVisibility,
      )}`;
    } else {
      this.args.mapping.destinationObjectType = this.args.sectionName;
    }
  }

  @action onSkipItem() {
    this.args.mapping.destinationAttributePath = '';
    this.args.mapping.destinationObjectType = '';
    this.args.mapping.status = 'skip';
    this.defaultIcon = 'unsubscribed';
  }

  get selectedAttribute(): IntercomAttribute | undefined {
    if (this.args.mapping.status === 'skip') {
      return undefined;
    }
    return this.importConfigurationService.matchingDestinationAttribute(this.args.mapping);
  }

  get defaultLabel() {
    return this.translate(this.args.mapping.status === 'skip' ? 'skip' : 'default');
  }

  get footerActions() {
    let actions = [
      {
        text: this.intl.t(this.translationKey('skip')),
        icon: 'unsubscribed',
        action: this.onSkipItem,
      },
    ];

    if (supportedSectionsForAttributeCreation.includes(this.args.sectionName)) {
      actions.push({
        text: this.intl.t(this.translationKey('new')),
        icon: 'new',
        action: this.openCreateAttributeModal,
      });
    }

    if (this.args.sectionName === 'ticket') {
      actions.push({
        text: this.intl.t(this.translationKey('copy')),
        icon: 'stars',
        action: this.copyTicketAttribute,
      });
    }

    return actions;
  }

  @action async openCreateAttributeModal() {
    let availableTypes = this.availableTypes.map((type: ATTRIBUTE_DESCRIPTOR_TYPES) => {
      return {
        text: this.intl.t(ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_TRANSLATION_KEY_MAP[type]),
        value: type,
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP[type],
      };
    });

    if (availableTypes.length > 0) {
      this.modalService.openModal('settings/modals/attribute-details', {
        allowArchival: false,
        typeToCreate: this.args.sectionName === 'contact' ? 'people' : 'company',
        showPeopleAndCompanyToggleTab: false,
        availableTypes,
        onSave: this.attributeCreated,
      });
    } else {
      captureException(
        new Error('Inline attribute creation modal failed because there are no mappable types'),
        {
          fingerprint: ['inline_attribute_creation_modal_no_mappable_types', 'failed'],
          extra: {
            appId: this.args.mapping.appIdCode,
            sourceAttribute: this.args.mapping.sourceAttributePath,
          },
          tags: {
            responsibleTeam: 'team-actions',
            responsible_team: 'team-actions',
          },
        },
      );

      this.notificationsService.notifyError(
        this.translate('create-attribute-without-mappable-types-error'),
      );
    }
  }

  @action async copyTicketAttribute() {
    this.loading = true;

    let ticketTypeId = this.importConfigurationService.getTicketTypeId(
      this.args.mappingVisibility,
    )!;

    let sourceAttribute = this.importConfigurationService.matchingSourceAttribute(
      this.args.mapping,
    )!;

    // try to find the attribute within the current list of attributes
    let matchingOption = this.mappingOptions.find(
      (mappingOption) => sourceAttribute.label === mappingOption.text,
    );

    if (matchingOption) {
      this.onSelectItem(matchingOption.value);
    } else {
      await this.createAttributeCopy(ticketTypeId, sourceAttribute);
    }

    this.loading = false;
  }

  attributeCreated = async (attribute: IntercomAttribute) => {
    let matchingOption = this.mappingOptions.find(
      (mappingOption) => attribute.identifier === mappingOption.value.identifier,
    );

    if (matchingOption) {
      this.onSelectItem(matchingOption.value);
    }
  };

  translationKey = (path: string) => {
    return `settings.data-import.${this.args.integrationCode}.config-modal.accordion.page.attr-selector.${path}`;
  };

  translate = (path: string) => {
    return this.intl.t(this.translationKey(path));
  };

  private async createAttributeCopy(ticketTypeId: string, sourceAttribute: Attribute) {
    let typeConversions = new Map<string, string>([
      ['boolean', 'boolean'],
      ['checkbox', 'boolean'],
      ['integer', 'integer'],
      ['decimal', 'decimal'],
      ['string', 'string'],
      ['text', 'string'],
      ['textarea', 'string'],
      ['date', 'datetime'],
    ]);

    try {
      let dataType = typeConversions.get(sourceAttribute.type);
      if (!dataType) {
        throw new DatatypeUnsupportedError();
      }

      let descriptor = await this.attributeUpdater.createTicketAttribute(
        ticketTypeId,
        sourceAttribute.label,
        dataType,
        {},
      );

      this.notificationsService.notifyConfirmation(this.translate('create-attribute-successful'));

      let matchingOption = this.mappingOptions.find(
        (mappingOption) => `ticket_attributes.${descriptor.id}` === mappingOption.value.identifier,
      );

      if (matchingOption) {
        this.onSelectItem(matchingOption.value);
      }
    } catch (error) {
      captureException(new Error('Copying Zendesk attribute failed'), {
        fingerprint: ['copy_zendesk_attribute', 'failed'],
        extra: {
          appId: this.args.mapping.appIdCode,
          sourceAttribute: this.args.mapping.sourceAttributePath,
          errorMessage: error.message,
          httpStatus: error.jqXHR?.status,
          dataType: sourceAttribute.type,
        },
        tags: {
          responsibleTeam: 'team-actions',
          responsible_team: 'team-actions',
        },
      });
      if (error.jqXHR?.status === 422) {
        this.notificationsService.notifyError(this.translate('create-attribute-duplicated-error'));
      } else if (error.jqXHR?.status === 400) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.errors);
      } else if (error instanceof DatatypeUnsupportedError) {
        this.notificationsService.notifyError(this.translate('create-attribute-unsupported-error'));
      } else {
        this.notificationsService.notifyError(this.translate('create-attribute-generic-error'));
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::SelectAttribute': typeof SelectAttribute;
    'settings/data-import/modal/select-attribute': typeof SelectAttribute;
  }
}
