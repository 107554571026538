/* import __COLOCATED_TEMPLATE__ from './saml-settings.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { UNLIMITED_USAGE } from 'embercom/lib/settings/copilot-access-editor/constants';
import { run } from '@ember/runloop';

export default class SamlSettings extends Component {
  @service appService;
  @service notificationsService;
  @service store;
  @service intl;

  @tracked idpSsoTargetUrl = this.args.samlAccount.idpSsoTargetUrl?.replace('https://', '');

  @tracked shouldShowPermissionsModal = false;
  @tracked permissionsObject = this._getDefaultPermissionsObject();

  _getDefaultPermissionsObject() {
    return this._getPermissionsObject(this.args.samlAccount.jitSettings);
  }

  _getPermissionsObject(permissions) {
    permissions = this.args.samlAccount.jitSettings || {};
    let permissionsObject = this.store.createRecord('jit-settings-permissions', permissions);
    return permissionsObject;
  }

  get canEnableJustInTimeProvisioning() {
    return !this.appService.app.is_test_app;
  }

  get disabledCopilotOptions() {
    return [UNLIMITED_USAGE];
  }

  get shouldShowCopilotAccessEditor() {
    return this.appService.app.canUseFinAiCopilotAddon;
  }

  @action
  updateIdpSsoTargetUrl() {
    // We need to run this in a runloop because the the on modifier does not schedule the event listener onto the run loop
    // https://github.com/emberjs/ember.js/issues/19222#issuecomment-1049773929
    run(() => {
      let targetUrl = this.idpSsoTargetUrl.trim().replace('https://', '') || '';
      this.idpSsoTargetUrl = targetUrl;
      this.args.samlAccount.idpSsoTargetUrl = `https://${targetUrl}`;
    });
  }

  @action
  updateIdpSsoTargetUrlOnPaste(event) {
    // We need to run this in a runloop because the the on modifier does not schedule the event listener onto the run loop
    // https://github.com/emberjs/ember.js/issues/19222#issuecomment-1049773929
    run(() => {
      let targetUrl = (event.clipboardData || window.clipboardData).getData('text');
      targetUrl = targetUrl.trim().replace('https://', '') || '';
      this.idpSsoTargetUrl = targetUrl;
      this.args.samlAccount.idpSsoTargetUrl = `https://${targetUrl}`;
    });
  }

  @action
  openPermissionsModal() {
    this.shouldShowPermissionsModal = true;
  }

  @action
  updatePermissions() {
    try {
      this.permissionsObject.validate();
      this.args.samlAccount.jitSettings = {
        ...this.permissionsObject.permissions(),
        ...this.permissionsObject.conversationAccess,
      };
      this.shouldShowPermissionsModal = false;
    } catch (error) {
      this.notificationsService.notifyError(error.message);
    }
  }

  @action
  closePermissionsModalAndRollback() {
    this.permissionsObject = this._getPermissionsObject(this.args.samlAccount.jitSettings);
    this.shouldShowPermissionsModal = false;
  }
}
