/* import __COLOCATED_TEMPLATE__ from './descriptor-details-modal.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DescriptorDetailsModal extends Component {
  @service intl;

  @action
  modalTitle(editMode, isConversationTicketType) {
    if (editMode) {
      return this.intl.t(
        'settings.ticket-data.ticket-type-configuration.ticket-attributes-modal-misc.edit-attribute',
      );
    }

    return isConversationTicketType
      ? this.intl.t(
          'settings.ticket-data.ticket-type-configuration.ticket-attributes-modal-misc.create-conversation-attribute',
        )
      : this.intl.t(
          'settings.ticket-data.ticket-type-configuration.ticket-attributes-modal-misc.create-attribute',
        );
  }
}
