/* import __COLOCATED_TEMPLATE__ from './ticket-status-modal.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';

interface Signature {
  Element: HTMLElement;
}

export default class TicketStatusModal extends Component<Signature> {
  destinationClass = (color: string) => {
    return `font-semibold text-${color}`;
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::TicketStatusModal': typeof TicketStatusModal;
    'settings/data-import/modal/ticket-status-modal': typeof TicketStatusModal;
  }
}
