/* import __COLOCATED_TEMPLATE__ from './select-fallback-team-or-admin.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type Team from 'embercom/models/team';
import type Admin from 'embercom/models/admin';

export interface Args {
  type: 'team' | 'admin';
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SelectFallbackTeamOrAdmin extends Component<Signature> {
  @service declare appService: any;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;
  @service declare intl: IntlService;
  @service declare modalService: any;

  get items() {
    let items;
    if (this.args.type === 'team') {
      items = this.appService.app.assignableTeams;
    } else if (this.args.type === 'admin') {
      if (this.appService.app.onPricing5) {
        items = this.appService.app.assignableAdmins;
      } else {
        items = this.appService.app.adminsWithInboxAccess;
      }
    }
    let options = items.map((item: Team | Admin) => {
      return {
        text: item.name,
        value: item.id,
        component: 'settings/data-import/modal/team-or-admin-dropdown-option',
        componentAttrs: { model: item },
      };
    });
    return options;
  }

  get itemId() {
    let itemId: string | null;
    if (this.args.type === 'team') {
      itemId = this.importConfigurationService.fallbackTeamId;
    } else if (this.args.type === 'admin') {
      itemId = this.importConfigurationService.fallbackAdminId;
    }

    if (this.items.some((item: { value: string }) => item.value === itemId)) {
      return itemId!;
    }

    return undefined;
  }

  @action onSelectItem(value: string) {
    if (this.args.type === 'team') {
      this.importConfigurationService.setFallbackTeamId(value);
    } else if (this.args.type === 'admin') {
      this.importConfigurationService.setFallbackAdminId(value);
    }
  }

  @action onDeselectItem() {
    if (this.args.type === 'team') {
      this.importConfigurationService.setFallbackTeamId(null);
    } else if (this.args.type === 'admin') {
      this.importConfigurationService.setFallbackAdminId(null);
    }
  }

  translationKey = (key: string) => {
    return `settings.data-import.migrate-from-zendesk.select-fallback.${this.args.type}.${key}`;
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::SelectFallbackTeamOrAdmin': typeof SelectFallbackTeamOrAdmin;
    'settings/data-import/modal/select-fallback-team-or-admin': typeof SelectFallbackTeamOrAdmin;
  }
}
