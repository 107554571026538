/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { VisibilityFilters } from './visibility-filters/visibility-filters';
import { MacroVisibility } from 'embercom/objects/inbox/macro';

export default class List extends Component {
  @service appService;
  @tracked selectedVisibility;

  constructor(owner, args) {
    super(owner, args);

    this.visibilityFilters = new VisibilityFilters(this.appService, owner);
    this.selectedVisibility = this.visibilityFilters.initialVisibility;
  }

  get numberOfMacros() {
    let filteredMacros = this.savedReplies.filter(
      (macro) => this.args.canUsePersonalMacros || macro.visibility !== MacroVisibility.Myself,
    );

    return filteredMacros.length;
  }

  get savedReplies() {
    if (!this.canFilter) {
      return this.args.sortedReplies;
    }

    return this.visibilityFilters.filterSavedReplies(
      this.selectedVisibility,
      this.args.sortedReplies,
    );
  }

  get privateMacros() {
    return this.savedReplies.filterBy('visibility', MacroVisibility.Myself);
  }

  get sharedMacros() {
    if (!this.args.canManageMacros) {
      return [];
    }
    return this.savedReplies.rejectBy('visibility', MacroVisibility.Myself);
  }

  get app() {
    return this.appService.app;
  }

  get visibilityFilterLabel() {
    return this.visibilityFilters.filterLabel(this.selectedVisibility);
  }

  get visibilityFilterItems() {
    return this.visibilityFilters.items;
  }

  get canFilter() {
    return this.args.canManageMacros && this.app.canSetMacroVisibility;
  }

  get firstReply() {
    if (this.privateMacros.length > 0) {
      return this.privateMacros[0];
    } else if (this.sharedMacros.length && this.args.canManageMacros) {
      return this.sharedMacros[0];
    }
    return null;
  }

  @action setFilter(value) {
    this.selectedVisibility = value;

    if (!this.savedReplies.includes(this.args.currentlySelected)) {
      this.args.updateSelectedReply.perform(this.firstReply);
    }
  }
}
