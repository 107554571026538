/* import __COLOCATED_TEMPLATE__ from './tag-deletion-modal.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class TagDeletionModal extends Component {
  @service intercomEventService;
  @service notificationsService;
  @service appService;
  @service modelDataCacheService;

  get app() {
    return this.appService.app;
  }

  @task({ drop: true })
  *deleteTag() {
    try {
      let tag = this.args.tag;
      yield tag.destroyRecord();
      this.app.tags.removeObject(tag);
      this.app.updateLocalCache(this.modelDataCacheService);
      this.notificationsService.notifyConfirmation(`${tag.name} was deleted`);
      this.args.onClose({ success: true });

      this.intercomEventService.trackAnalyticsEvent({
        action: 'deleted',
        object: tag,
      });
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyError('Delete request failed, please try again.');
      this.args.onClose({ success: false });
    }
  }

  @action
  focusPrimaryButton(element) {
    let buttonElement = element.querySelector('button[class*="o__primary"]');
    if (buttonElement) {
      buttonElement.focus();
    }
  }
}
