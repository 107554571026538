/* import __COLOCATED_TEMPLATE__ from './settings-segment-component.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import SegmentService from 'embercom/lib/segments/segment-service';

export default class SettingsSegmaneCompComponent extends Component {
  @service modalService;

  @action
  saveSegment(newName) {
    let segment = this.args.segment;
    segment.set('name', newName);
    segment.save();
  }

  @action
  requestDeletion() {
    let modalComponent = 'settings/modals/checking-dependents';
    let segment = this.args.segment;
    let segmentService = SegmentService.create({
      appId: this.args.app.id,
      segment,
    });
    let options = { dependentObjectService: segmentService };
    this.modalService.openModal(modalComponent, segment, options);
  }
}
