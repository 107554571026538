/* import __COLOCATED_TEMPLATE__ from './visibility.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { MacroVisibility } from 'embercom/objects/inbox/macro';

export default class Visibility extends Component {
  @service appService;
  @service intl;
  @tracked teamPaywall;

  get app() {
    return this.appService.app;
  }

  get visibility() {
    return this.args.visibility;
  }

  get showTeamSelect() {
    return this.visibility === MacroVisibility.Teams && this.app.canSetMacroVisibility;
  }

  get noPermissions() {
    let permissions = this.app.currentAdmin.currentAppPermissions;
    return !permissions.can_manage_saved_replies;
  }

  get selectItems() {
    let items = [
      {
        value: MacroVisibility.Everyone,
        text: this.intl.t('inbox.manage-macros.visibility.everyone'),
        isSelected: this.visibility === MacroVisibility.Everyone,
      },
    ];

    let teamItem = {
      value: MacroVisibility.Teams,
      text: this.intl.t('inbox.manage-macros.visibility.specific-teams'),
      isSelected: this.visibility === MacroVisibility.Teams,
    };

    if (this.teamPaywall?.isActive) {
      teamItem = {
        ...teamItem,
        component: 'paywalls/select-item',
        componentShouldReplaceItem: false,
        paywallOpenUpgradeModal: this.teamPaywall?.openUpgradeModal,
        paywallAnalyticsEventData: this.teamPaywall?.analyticsEventData,
      };
    }

    items.push(teamItem);
    items.push({
      value: MacroVisibility.Myself,
      text: this.intl.t('inbox.manage-macros.visibility.myself-only'),
      isSelected: this.visibility === MacroVisibility.Myself,
      isDisabled: !this.app.currentAdmin.currentAppPermissions.can_use_personal_macros,
      componentShouldReplaceItem: false,
      component: 'settings/saved-replies/owner-select-item',
    });

    return items;
  }

  @action
  onChange(newVisibility) {
    if (newVisibility === MacroVisibility.Teams) {
      this.args.onVisibilityChange([], newVisibility);
    } else {
      this.args.onVisibilityChange(null, newVisibility);
    }
  }

  @action
  selectedTeamsChanged(newTeams) {
    this.args.onVisibilityChange(newTeams, this.visibility);
  }
}
