/* import __COLOCATED_TEMPLATE__ from './map-ticket-attributes-widget.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type IntlService from 'embercom/services/intl';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type MappingVisibility } from 'embercom/models/crm/attribute-mapping';

export interface Args {
  mappingVisibility: MappingVisibility;
  integrationCode: string;
  sectionName: string;
  section: any;
  onDone: () => void;
  onBack: () => void;
  hasPrevStep: () => boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class MapTicketAttributesWidget extends Component<Signature> {
  @service declare intl: IntlService;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  @tracked showTicketTypeModal = false;
  readonly createTicketTypeCategory = TicketCategory.Task;

  get ticketTypeId() {
    return this.importConfigurationService.getTicketTypeId(this.args.mappingVisibility);
  }

  get ticketTypeName() {
    if (this.args.mappingVisibility === 'public') {
      return this.importConfigurationService.publicTicketType?.name;
    } else {
      return this.importConfigurationService.privateTicketType?.name;
    }
  }

  @action onTicketTypeModalClose() {
    this.showTicketTypeModal = false;
  }

  @action openTicketTypeModal() {
    this.showTicketTypeModal = true;
  }

  @action onDone() {
    this.args.onDone();
  }

  translationKey = (path: string) => {
    return `settings.data-import.${this.args.integrationCode}.config-modal.accordion.sections.ticket.steps.ticket.${path}`;
  };

  translation = (path: string, options = {}) => {
    return this.intl.t(this.translationKey(path), options);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::MapTicketAttributesWidget': typeof MapTicketAttributesWidget;
    'settings/data-import/modal/map-ticket-attributes-widget': typeof MapTicketAttributesWidget;
  }
}
