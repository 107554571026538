/* import __COLOCATED_TEMPLATE__ from './app-sso-domains.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';

const DOMAIN_REGEX = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;

export default class AppSsoDomains extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service intl;

  @tracked domain = '';
  @tracked appSsoDomains = this.args.samlAccount.appSsoDomains;
  @tracked newDomain = '';
  @tracked showDnsModal = false;
  @tracked isVerifying = false;
  @tracked canShowError = false;

  get domainInputIsInvalid() {
    return this.domain && !this.domain.match(DOMAIN_REGEX);
  }

  get domainButtonDisabled() {
    return !this.domain || this.domainInputIsInvalid || this.domainAlreadyAdded;
  }

  get domainAlreadyAdded() {
    return this.appSsoDomains
      .toArray()
      .some((appSsoDomain) => appSsoDomain.domain?.toLowerCase() === this.domain?.toLowerCase());
  }

  _addAppSsoDomain(appSsoDomain) {
    this.appSsoDomains.pushObject(appSsoDomain);
    this.args.samlAccount.appSsoDomainIds = this.appSsoDomains.mapBy('id');
    this.canShowError = true;
    this.showDnsModal = false;
  }

  @task
  *addDomain() {
    try {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'added',
        object: 'app_sso_domain',
      });
      let newDomain = this.store.createRecord('app-sso-domain', {
        domain: this.domain,
      });
      yield newDomain.save();

      this.domain = '';
      this.newDomain = newDomain;
      if (newDomain.state === 'verified' || newDomain.state === 'active') {
        this._addAppSsoDomain(newDomain);
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.security.app-sso-domains.domain-added-notify'),
        );
      } else {
        this.showDnsModal = true;
      }
    } catch (e) {
      if (e?.jqXHR?.status === 422) {
        this.notificationsService.notifyError(e.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.security.app-sso-domains.could-not-add-error'),
        );
      }
    }
  }

  @action
  deleteDomain(appSsoDomain) {
    this.appSsoDomains.removeObject(appSsoDomain);
    this.args.samlAccount.appSsoDomainIds = this.appSsoDomains.mapBy('id');
    appSsoDomain.unloadRecord();
    this.canShowError = true;
  }

  @task
  *verifyDomain() {
    this.isVerifying = true;
    let errorMessage = this.intl.t('settings.security.app-sso-domains.verify-error', {
      domain: this.newDomain.domain,
    });

    try {
      let response = yield ajax({
        url: '/ember/app_sso_domains/verify',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          domain: this.newDomain.domain,
        }),
      });

      if (response.domain_verified) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'verified',
          object: 'app_sso_domain',
        });
        this._addAppSsoDomain(this.newDomain);
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.security.app-sso-domains.domain-verified-notify'),
        );
      } else {
        this.notificationsService.notifyError(errorMessage);
      }
    } catch (e) {
      this.notificationsService.notifyError(errorMessage);
    }
    this.isVerifying = false;
  }

  @action
  closeModal() {
    this.showDnsModal = false;
  }
}
