/* import __COLOCATED_TEMPLATE__ from './select-ticket-type.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type TicketType from 'embercom/models/inbox/ticket-type';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import type Store from '@ember-data/store';
import { type MappingVisibility } from 'embercom/models/crm/attribute-mapping';

export interface Args {
  mappingVisibility: MappingVisibility;
  integrationCode: string;
  showTicketTypeModal: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SelectTicketType extends Component<Signature> {
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked items: TicketType[] = [];
  @tracked selectedItemId: string | undefined = undefined;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.refreshTicketTypes();
  }

  @action refreshTicketTypes() {
    taskFor(this.loadTicketTypes).perform();
  }

  @dropTask *loadTicketTypes(): TaskGenerator<void> {
    let ticketCategory =
      this.mappingVisibility === 'private' ? TicketCategory.Task : TicketCategory.Request;

    let items = yield this.store.findAll('inbox/ticket-type');
    this.items = items
      .filter((item: TicketType) => item.category === ticketCategory && !item.archived)
      .map((item: TicketType) => {
        return {
          text: item.name,
          value: item.id,
          component: 'settings/data-import/modal/select-ticket-type-dropdown-option',
          componentAttrs: { emojiUri: item.emojiUri },
        };
      });

    let ticketTypeId = this.importConfigurationService.getTicketTypeId(this.mappingVisibility);
    this.selectedItemId = ticketTypeId === null ? undefined : ticketTypeId;
  }

  get mappingVisibility() {
    return this.args.mappingVisibility;
  }

  get defaultLabel() {
    return this.translation('default');
  }

  @action onSelectItem(value: string) {
    this.selectedItemId = value;
    if (this.mappingVisibility === 'private') {
      this.importConfigurationService.setPrivateTicketTypeId(value);
    } else {
      this.importConfigurationService.setPublicTicketTypeId(value);
    }
  }

  @action openCreateTicketTypeModal() {
    this.args.showTicketTypeModal();
  }

  translationKey = (path: string) => {
    return `settings.data-import.${this.args.integrationCode}.select-ticket-type.${path}`;
  };

  translation = (path: string) => {
    return this.intl.t(this.translationKey(path));
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Settings::DataImport::Modal::SelectTicketType': typeof SelectTicketType;
    'settings/data-import/modal/select-ticket-type': typeof SelectTicketType;
  }
}
