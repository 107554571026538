/* import __COLOCATED_TEMPLATE__ from './attribute-descriptors.hbs'; */
/* RESPONSIBLE TEAM: team-data-foundations */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class AttributeDescriptors extends Component {
  @service store;
  @service intl;
  @service notificationsService;
  @service intercomEventService;
  @service appService;
  @service modelDataCacheService;
  @tracked currentTab = 'live';
  @tracked attributeDescriptors = this.args.customObject.attributeDescriptors;
  standardAttributeDescriptors = this.args.customObject.standardAttributeDescriptors;

  get app() {
    return this.appService.app;
  }

  get liveAttributeDescriptors() {
    return this.attributeDescriptors
      .filterBy('editable')
      .map((attr) => attr)
      .concat(this.standardAttributeDescriptors)
      .rejectBy('archived');
  }

  get archivedAttributeDescriptors() {
    return this.attributeDescriptors.filterBy('editable').filterBy('archived');
  }

  get selectedAttributeDescriptors() {
    if (this.currentTab === 'live') {
      return this.liveAttributeDescriptors;
    } else {
      return this.archivedAttributeDescriptors;
    }
  }

  get columns() {
    let columns = [
      {
        label: this.intl.t(
          'settings.custom-object-data.list.attribute-descriptors.column-attribute',
        ),
        valuePath: 'name',
      },
      {
        label: this.intl.t('settings.custom-object-data.list.attribute-descriptors.column-format'),
        valuePath: 'dataType',
        width: '20%',
      },
    ];

    if (this.app.canUseCustomObjectsRequiredAttributes) {
      columns.push({
        label: this.intl.t(
          'settings.custom-object-data.list.attribute-descriptors.column-required',
        ),
        valuePath: 'required',
        width: '15%',
      });
    }

    if (this.currentTab === 'archived') {
      columns.push({
        label: this.intl.t('settings.custom-object-data.list.attribute-descriptors.label-archived'),
        valuePath: 'archivedAt',
        width: '15%',
      });
    } else {
      columns.push({ label: '', valuePath: 'editAttribute', width: '0' });
    }

    return columns;
  }

  @task({ drop: true })
  *unarchiveAttribute(attributeDescriptor) {
    try {
      yield attributeDescriptor.unarchive();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.custom-object-data.modals.unarchive.notification-success', {
          type: attributeDescriptor.name,
        }),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'unarchive',
        object: 'archive',
        place: 'custom_object',
        archive_object_type: 'attribute',
        archive_object_id: attributeDescriptor.id,
      });
      this.app.updateLocalCache(this.modelDataCacheService);
      if (this.archivedAttributeDescriptors.length === 0) {
        this.currentTab = 'live';
      }
    } catch (error) {
      attributeDescriptor.rollbackAttributes();
      this.notificationsService.notifyError(
        this.intl.t('settings.custom-object-data.modals.unarchive.notification-failure', {
          type: attributeDescriptor.name,
        }),
      );
    }
  }
}
